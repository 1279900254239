import { QueryHookOptions, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import RegistryEntity, { TRegistry } from "../entities/RegistryEntity";
import PostalAddressEntity, { TPostalAddress } from "../entities/PostalAddressEntity";

export type GetRegistryVariables = {
    id: string,
}

export type GetRegistryResult = {
    registry: TRegistry & {
        postalAddress: TPostalAddress,
    },
}

const query = gql`
    query getRegistry($id: ID!) {
        registry(id: $id) {
            ...Registry,
            postalAddress {
                ...PostalAddress,
            }
        }
    }

    ${RegistryEntity.fragment}
    ${PostalAddressEntity.fragment}
`;

export default function (options: QueryHookOptions<GetRegistryResult, GetRegistryVariables>) {
    return useQuery<GetRegistryResult, GetRegistryVariables>(query, options);
}