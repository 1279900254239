import React, { CSSProperties } from 'react';

import { RadioChangeEvent } from 'antd/lib/radio';

import Radio from '../../../../../aqua-delivery-web-client-ui/components/ui/Radio';

export const PeriodButton: React.FC<{
    caption: string;
    value: string;
    onChange?: (e: RadioChangeEvent) => void;
}> = ({ caption, value, onChange }) => {
    return (
        <Radio.Button value={value} onChange={onChange}>
            {caption}
        </Radio.Button>
    );
};

type PeriodSwitcherProps = {
    onChange: (e: RadioChangeEvent) => void;
    defaultValue?: string;
    value: string | undefined | null;
    containerStyles?: CSSProperties;
};

const PeriodSwitcher: React.FC<PeriodSwitcherProps> = ({
    onChange,
    defaultValue,
    containerStyles,
    children,
    value,
}) => {
    return (
        <Radio.Group
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            className={'period-switcher-group'}
            style={containerStyles}
        >
            {children}
        </Radio.Group>
    );
};

export default PeriodSwitcher;
