import React, { useMemo } from 'react';

import { CustomLayer, Point } from '@nivo/line';

import useLocalTranslation from '../../../../../../../../hooks/useLocalTranslation';

import { FillingGraphLegends, MAX_FILLINGS_IN_DAY } from '../FillingsGraph';

const BarLayer: CustomLayer = props => {
    const legends = useLocalTranslation(
        t => t.vending.fillingsGraph.legends,
        t => t,
    );

    const { points, innerWidth, innerHeight } = props;

    const serieSequence = [
        legends[FillingGraphLegends.MobileAppVolume],
        legends[FillingGraphLegends.TotalVolume],
    ];

    const groupedPoints = useMemo(
        () =>
            points.reduce<Record<number, Point[]>>((groups, point) => {
                const groupIndex = serieSequence.findIndex(group => group === point.serieId);

                if (!groups[point.x]) {
                    groups[point.x] = [];
                }

                groups[point.x][groupIndex] = point;

                return groups;
            }, {}),
        [points, serieSequence],
    );

    const barCount = 2;

    const barGroupWidth = Math.max(2, innerWidth / MAX_FILLINGS_IN_DAY) * 0.9;

    const barMaxWidth = barGroupWidth / barCount;
    const barWidth = barMaxWidth;

    return Object.entries(groupedPoints).map(([x, points]) => {
        return points.map((point, index) => {
            return (
                <rect
                    width={barWidth}
                    height={innerHeight - point.y}
                    y={point.y}
                    x={point.x - barGroupWidth / 2 + barMaxWidth * index}
                    fill={point.serieColor}
                    key={point.id}
                />
            );
        });
    });
};

export default BarLayer;
