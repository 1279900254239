import React from 'react';

import { Locale } from 'antd/lib/locale-provider';

import { I18nContext } from '../aqua-delivery-web-client-ui/i18n';

import ru from 'antd/es/locale/ru_RU';
import en from 'antd/es/locale/en_US';

type AntdLocale = {
    antdLocale: Locale;
};

const locales: Record<string, Locale> = {
    ru,
    en,
};

export default function (): AntdLocale {
    const { locale } = React.useContext(I18nContext);

    return {
        antdLocale: locales[locale],
    };
}
