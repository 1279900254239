import { gql } from "apollo-boost";
import { BaseEntity } from "../../types";

export declare type TPostalAddress = BaseEntity<{
    addressCountry: string,
    addressLocality: string,
    addressRegion: string,
    postOfficeBoxNumber: string,
    postalCode: string,
    streetAddress: string,
    coordinates: {
        latitude: number,
        longitude: number,
    }
}>


const fragment = gql`
fragment PostalAddress on PostalAddress {
    id,
    addressCountry,
    addressLocality,
    addressRegion,
    postOfficeBoxNumber,
    postalCode
    streetAddress,
    coordinates {
        id,
        latitude,
        longitude,
    }
}`;

const PostalAddressEntity = {
    fragment,
    createId: (id: string) => `/iot/postal_addresses/${id}`
};

export default PostalAddressEntity