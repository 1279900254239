import { FillingGraphLegends } from '../../routes/partner-cabinet/routes/vending/components/graphs/FillingsGraph/FillingsGraph';
import { VendingTelemetryStatus } from '../../routes/partner-cabinet/routes/vending/utils/vendingTelemetryUtils';
import { NamedPeriod } from '../../types';

export default {
    title: 'Киоски',
    liters: 'л.',
    meters: 'м.',
    registry: {
        timezoneTitle: 'Временная зона киоска',
        timezoneHelper: 'Для отображения времени на графиках используется временная зона киоска',
    },
    table: {
        columns: {
            address: 'Адрес',
            temperature: 'Температура, ℃',
            waterLevel: 'Уровень воды, м.',
            lastActivity: 'Последняя активность',
            timezone: 'Временная зона киоска',
        },
        timezoneHelper: 'Время отображается во временной зоне киоска',
        onTheMap: 'На карте',
    },
    waterVolume: {
        title: 'Уровень воды',
    },
    temperature: {
        title: 'Температура в киоске',
    },
    fillings: {
        title: 'Наливы',
    },
    temperatureGraph: {
        title: 'Динамика температуры',
        legend: 'Температура, ℃',
    },
    waterVolumeGraph: {
        title: 'Динамика уровня воды',
        legend: 'Уровень воды, м.',
    },
    fillingsGraph: {
        title: 'Динамика наливов',
        legend: 'Объём, л.',
        legends: {
            [FillingGraphLegends.TotalVolume]: 'Общий объём',
            [FillingGraphLegends.MobileAppVolume]: 'Приложение',
        },
        parameters: {
            title: 'Показатели за период',
            totalFillings: 'Общий объём наливов',
            mobileFillings: 'Через приложение',
        },
    },
    periodSwitcher: {
        [NamedPeriod.Days]: 'День',
        [NamedPeriod.Weeks]: 'Неделя',
        [NamedPeriod.Months]: 'Месяц',
    },
    datePicker: {
        from: 'Начало периода',
        to: 'Конец периода',
    },
    vendingPopup: {
        temperature: 'Температура',
        waterLevel: 'Давление воды',
        lastActivity: 'Последняя активность',
        liters: 'л.',
        meters: 'м.',
        goToVendingScreen: 'Перейти на экран киоска',
        timezone: 'Временная зона',
    },
    colorLegend: {
        [VendingTelemetryStatus.Active]: 'Работает',
        [VendingTelemetryStatus.NonActive]: 'Не работает',
        [VendingTelemetryStatus.Critical]: 'Критическое состояние',
        title: 'Состояние киоска',
    },
};
