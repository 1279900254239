export default {
    title: 'Materials',
    table: {
        columns: {
            material: 'Material',
            purpose: 'How can it help you?',
        },
        preview: 'Preview',
    },
};
