export default {
    blocks: {
        marketerCabinet: {
            title: 'Marketing Panel',
            body: 'View reports and analyze data',
        },
        orderCabinet: {
            title: 'Sales Panel',
            body: 'View, add and edit orders and clients',
        },
        settingsCabinet: {
            title: 'Settings Panel',
            body: 'Make changes to products, services, images, descriptions, and prices',
        },
        clientApp: {
            title: 'My Mobile App',
            //body: "Make changes to products, services, images, descriptions, and prices"
        },
        orderSite: {
            title: 'My Website',
            //body: "Make changes to products, services, images, descriptions, and prices"
        },
        knowledge: {
            title: 'Knowledge base',
            body: 'Find answers to questions about working with the system',
        },
    },
    welcome: {
        title: 'Thank you',
        title2: 'for registration!',
        subTitle: 'We will call you soon :)',
        phone: 'Or you can contact us by phone',
        subPhone: 'for further actions',
    },
    labels: {
        login: 'Login',
        password: 'Password',
        links: {
            apple: 'App Store',
            google: 'Google Play',
            smartLink: 'Smart Link',
        },
    },
    tooltips: {
        copy: 'Copy',
        showPassword: 'Show password',
        hidePassword: 'Hide password',
        smartLink:
            'The address of a special page which redirects to the application store automatically',
    },
    messages: {
        login: 'Username copied',
        password: 'Password copied',
        linkCopied: 'Link copied',
    },
    buttons: {
        goToCabinet: 'Go',
    },
    documents: {
        title: 'Documents',
        content: {
            datepiker: {
                from: 'Beginning of period',
                to: 'End of period',
                select: 'All orders',
            },
            table: {
                label: 'Bill for',
                date: 'Date',
                total: 'Total',
                panel: {
                    header: 'Other',
                    extra: 'Act',
                    content: 'Act',
                },
                error: 'No invoices found',
            },
        },
        state: {
            invoice_created: 'Pay',
            invoice_paid: 'Paid',
            invoice_archived: 'Archived',
        },
    },
};
