import { appScreens } from './../../routes/partner-cabinet/routes/banners/utils';
export default {
    title: {
        banners: 'Баннеры',
        newBanner: 'Новый баннер',
        editBanner: 'Редактировать баннер',
        editBannerShort: 'Редактировать',
        editStoryShort: 'Редактировать',
        displayLimits: 'Ограничение на показ баннера:',
    },
    types: {
        ClickBanner: 'Рекламный баннер',
        Story: 'История',
        InfoBanner: 'Информационный баннер',
        ProductSetBanner: 'Набор продуктов ',
    },
    form: {
        label: {
            type: 'Тип баннера',
            deliveryAreas: 'Зоны доставки',
            position: 'Позиция',
            bannerPreview: 'Превью баннера',
            storyPreview: 'Превью истории',
            banner: 'Баннер',
            story: 'История',
            storySlide: 'Слайд истории',
            link: 'Ссылка',
            screenInApp: 'Страница в приложении',
            productSetNumber: 'Номер набора продуктов',
            buttonCaption: 'Текст на кнопке',
            duration: 'Длительность',
            description: 'Описание',
            pageMobile: 'Страница',
            linkType: 'Тип ссылки',
            targetTab: 'Вкладка',
            linkTypes: {
                external: 'Внешняя ссылка',
                deeplink: 'На страницу в приложении',
                notSpecified: 'Не указан тип ссылки',
            },
            appScreens: {
                catalog: 'Каталог',
                about: 'О компании',
                invite: 'Пригласить друга',
                chat: 'Чат',
                contact: 'Форма обратной связи',
                mainProfile: 'Основной экран профиля',
                myProfile: 'Экран с данными профиля',
                coinsHistory: 'История начисления баллов',
            },
            enablePeriodLimit: 'По времени:',
            enableOrdersLimit: 'По количеству заказов клиента:',
            showOnStart: 'Показывать при старте',
            active: 'Активен',
        },
        button: {
            addBanner: 'Добавить баннер',
            addStory: 'Добавить историю',
            addSlideStory: 'Добавить слайд',
            deleteBanner: 'Удалить баннер',
            deleteStory: 'Удалить историю',
            deleteSlideStory: 'Удалить слайд',
            saveChanges: 'Сохранить изменения',
        },
        deleteConfirmation: {
            titleBanner: 'Вы действительно ходите удалить баннер?',
            titleStory: 'Вы действительно ходите удалить историю?',
            titleSlideStory: 'Вы действительно ходите удалить слайд?',
            content: 'Отменить данное действие невозможно',
            ok: 'Да, удалить',
            cancel: 'Отмена',
        },
        error: {
            required: 'Обязательное поле',
            notSpecified: 'Не определены для партнера',
            functionalityNotConnected: 'Функционал не подключен',
        },
        help: {
            noLonger: 'Не дольше {{DELAY_MAX_VALUE}} секунд',
            symbolsLeft: 'Символов осталось',
            uploadPreview: `Загрузить изображение\n\nРазмер превью любой, соотношение сторон 14:15\nНе тяжелее двух мегабайт`,
            uploadBanner: `Загрузить изображение\n\nРазмер баннера любой, соотношение сторон 2:1\nНе тяжелее двух мегабайт`,
            uploadStorySlide: `Загрузить изображение\n\nРазмеры слайда:\n\nВысота: 1920 px\nШирина: 1080 px\n\nНе тяжелее\nдвух мегабайт`,
        },
        message: {
            bannerUpdated: 'Баннер обновлен',
            storyUpdated: 'История обновлена',
            storySlideUpdated: 'Слайд обновлен',
            bannerDeleted: 'Баннер удален',
            storyDeleted: 'История удалена',
            storySlideDeleted: 'Слайд удален',
            clickBannerAdded: 'Рекламный баннер добавлен',
            storyAdded: 'История добавлена',
            storySlideAdded: 'Слайд добавлен',
            infoBannerAdded: 'Информационный баннер добавлен',
            productSetBannerAdded: 'Набор продуктов добавлен',
        },
        suffix: {
            sec: 'сек.',
            optional: '(необязательно)',
        },
    },
    tooltips: {
        limitQuantityOrders:
            'Баннер видят только клиенты, у которых менее {{limitQuantityOrders}} заказов.',
        period: {
            activeFrom: 'Баннер отображается только с {{activeFrom}}.',
            activeTo: 'Баннер отображается только до {{activeTo}}',
            activeFromTo: 'Баннер отображается только с {{activeFrom}} до {{activeTo}}',
        },
        showOnStart: 'Баннер отображается во время запуска приложения.',
        settings: 'Настройки.',
    },
    emptyDescription: 'Описание отсутствует',
};
