export default {
    title: 'Курьеры',
    addCourier: 'Курьер',
    table: {
        columns: {
            name: 'ФИО',
            phoneNumber: 'Мобильный номер',
            email: 'Электронная почта',
        },
        tooltips: {
            notVerified: 'Курьер еще не подтвержден системой',
            deleteCourier: 'Удалить курьера',
        },
    },
    form: {
        title: 'Новый курьер',
        fields: {
            firstName: {
                label: 'Имя',
                placeholder: 'Иван',
            },
            middleName: {
                label: 'Фамилия',
                placeholder: 'Иванов',
            },
            familyName: {
                label: 'Отчество',
                placeholder: 'Иванович',
            },
            email: {
                label: 'E-mail',
                placeholder: 'example@mail.ru',
            },
            phoneNumber: {
                label: 'Телефон',
            },
            password: {
                label: 'Пароль',
            },
        },
        require: 'Поле обязательно для заполнения',
        submitButton: 'Добавить',
        messages: {
            success: 'Курьер создан',
        },
    },
    deleteButton: {
        title: 'Вы точно хотите удалить курьера {{firstName}} {{middleName}}?',
        titleNoMiddleName: 'Вы точно хотите удалить курьера {{firstName}}?',
        content: 'Это действие невозможно отменить.',
        ok: 'Да, удалить',
        cancel: 'Нет',
        messages: {
            success: 'Курьер удален',
        },
    },
};
