import { TranslateOptions } from "i18n-js";
import { useMemo } from "react";

import { t } from "../i18n";

export type Pluralization = { path: string, config?: TranslateOptions };

export type Path = string | Pluralization;

export type PathsReturnType<T> = T extends Path[] ? string[] : Record<keyof T, string>;

export type Paths = Record<string, Path> | Path[];

const useTranslation = <T extends Paths, R extends PathsReturnType<T>>(paths: T): R => {
    const translate = (path: Path) => {
        if (typeof path === 'string') {
            return t(path);
        }

        return t(path.path, path.config);
    }

    return useMemo(() => {
        if (Array.isArray(paths)) {
            return paths.map(path => translate(path));
        }

        return Object.fromEntries(Object.entries(paths).map(path => [path[0], translate(path[1])]));
    }, [paths]) as R;
}

export default useTranslation;