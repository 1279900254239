export default {
    title: 'Кабинет партнёра',
    labels: {
        tryOrderCabinet: 'Попробуйте демо-версию системы для диспетчеров',
        tryMobileApp: 'и демо-версию клиентского приложения',
        loading: 'Дождитесь окончания загрузки',
    },
    buttons: {
        skip: 'Пропустить',
        next: 'Далее',
        done: 'Завершить',
    },
    steps: {
        password: {
            title: 'Установите пароль, чтобы пользоваться системой',
            fields: {
                password: {
                    label: 'Пароль',
                    placeholder: 'Постарайтесь, чтобы пароль был нетривиальным*',
                    help: '* используйте буквы, цифры и знаки препинания (желательно)',
                    validators: {
                        require: 'Поле обязательно для заполнения',
                        equal: 'Пароли должны совпадать',
                    },
                },
                repeatPassword: {
                    label: 'Повторите пароль',
                    validators: {
                        require: 'Поле обязательно для заполнения',
                        equal: 'Пароли должны совпадать',
                    },
                },
            },
        },
        company: {
            title: 'Нам нужно еще немного данных о вашей компании',
            fields: {
                partnerName: {
                    label: 'Название компании',
                    placeholder: 'ООО "Компания"',
                    validators: {
                        require: 'Поле обязательно для заполнения',
                    },
                },
                location: {
                    label: 'Город',
                    validators: {
                        require: 'Поле обязательно для заполнения',
                    },
                },
                itn: {
                    label: 'ИНН',
                    placeholder: '1234567890',
                    validators: {
                        require: 'Поле обязательно для заполнения',
                    },
                },
                currency: {
                    label: 'Используемая валюта',
                    validators: {
                        require: 'Поле обязательно для заполнения',
                    },
                },
                country: {
                    label: 'Страна',
                    validators: {
                        require: 'Поле обязательно для заполнения',
                    },
                },
                contractEtching: {
                    labelStart: 'Я подтверждаю, что ознакомился с',
                    labelLink: ' договором-оферты ',
                    labelFinish: 'и принимаю его условия',
                    validators: {
                        require: 'Подтвердите, что согласны с договором-оферты',
                    },
                },
            },
        },
    },
};
