export default {
    title: "Company Panel",
    tabs: {
        companyProfile: "Company profile",
        pricingPlans: "Pricing plans",
        integrations: "Integrations",
    },
    companyProfile: {
        fields: {
            companyName: {
                label: "Company name",
                placeholder: "Type your company name",
                validations: {
                    require: "This field is required",
                }
            },
            companyLogo: {
                label: "Logo",
                validations: {
                    image: "Logo should be an image",
                }
            },
            companyLocations: {
                label: "Cities:",
            },
            newCity: {
                label: "Add city",
                validations: {
                    require: "Choose your city from the list",
                }
            },
            itn: {
                label: "EIN Number",
                placeholder: "Type your EIN Number",
                validators: {
                    notValid: 'EIN Number is not valid',
                }
            }
        },
        submitButton: "Save",
    },
    plans: {
        pricesTable: {
            title: 'Pricing plan — ',
        },
        fields: {
            pricingPlanDuration: 'Period',
            pricingPlanMonthlyPrice: 'Monthly payment',
            pricingPlanPrice: 'Price',
        },
        tariffDocumentName: "Pricing plans.pdf",
        yourPlan: "your pricing plan",
        changePlan: "To change the pricing plan, you can contact us at",
    },
    integrations: {
        amo: {
            title: 'AmoCRM',
            amoButtonTitle: 'Connect amoCRM',
            amoPopupTitle: 'Grant access for integration',
            connected: 'Connected',
        },
    },
    messages: {
        successPricingPlanUpdate: "Your pricing plan was successfully updated",
        successCompanyLogoUpdate: "Your company logo was successfully updated",
        successCompanyNameUpdate: "Your company name was successfully updated",
        successCompanyLocationsUpdate: "Cities list was successfully updated",
        successCompanyLocationCreate: "New city was successfully updated",
        successCompanyItnUpdate: "EIN Number was successfully updated"
    }
}