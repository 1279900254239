import React from 'react';

import { message } from 'antd';
import Axios, { AxiosRequestConfig } from 'axios';
import ApolloClient from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import {
    InMemoryCache,
    IntrospectionFragmentMatcher,
    NormalizedCacheObject,
} from 'apollo-cache-inmemory';
import { ApolloProvider } from '@apollo/react-hooks';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';

import { validationErrors } from '../../../aqua-delivery-client-graphql/utils';
import Scheme from '../../../aqua-delivery-client-graphql/Scheme';
import AuthService from '../../AuthService';
import { NetworkContext, NetworkContextProps } from './NetworkContext';
import { ServiceName, config } from '../../../config';
import createApi, { TFetch } from '../../customFetch';
import Loader from '../loader/Loader';

const clients: Record<string, ApolloClient<NormalizedCacheObject>> = {};
const schemas: Record<string, Scheme> = {};
const requests: Record<string, TFetch> = {};

export type NetworkLayerProps = {
    service: ServiceName;
    loader?: () => any;
    enableScheme?: boolean;
    enrichTokenOnInit?: boolean;
};

const createAssetUrl = (serviceName: ServiceName) => (assetUrl: string) => {
    const serviceUrl = config.api[serviceName].domain;

    const isAbsoluteAssetUrl = /^.+?:\/\//.test(assetUrl);

    return isAbsoluteAssetUrl ? assetUrl : `${serviceUrl}${assetUrl}`;
};

const NetworkLayer: React.FC<NetworkLayerProps> = ({
    children,
    service,
    loader,
    enableScheme = false,
    enrichTokenOnInit = false,
}) => {
    const serviceUri = config.api[service].base;

    const [client, setClient] = React.useState<ApolloClient<NormalizedCacheObject> | null>(
        clients[serviceUri],
    );
    const [scheme, setScheme] = React.useState<Scheme | null>(schemas[serviceUri]);
    const [request, setRequest] = React.useState<TFetch | null>(requests[serviceUri]);

    const initCacheManager = async (api: TFetch) => {
        const configCache: any = {
            dataIdFromObject: (r: any) => {
                return r.id;
            },
            addTypename: true,
        };

        if (!enableScheme || schemas[serviceUri]) {
            return {
                cache: new InMemoryCache(configCache),
                scheme: schemas[serviceUri],
            };
        }

        const result: any = await api.fetch({
            method: 'POST',
            data: Scheme.introspectionQuery,
        });

        const scheme = new Scheme(result.data);

        setScheme(scheme);

        const fragmentsTypes = scheme.fragmentTypes;

        const fragment_matcher = fragmentsTypes
            ? new IntrospectionFragmentMatcher({
                  introspectionQueryResultData: fragmentsTypes,
              })
            : undefined;

        if (fragment_matcher) {
            configCache.fragmentMatcher = fragment_matcher;
        }

        return {
            cache: new InMemoryCache(configCache),
            scheme,
        };
    };

    const initApolloClient = async () => {
        if (!config.api[service]) {
            throw Error('config does not found');
        }

        try {
            const api = createApi(serviceUri, { enrichTokenUrl: config.api[service].enrichToken });

            api.setBaseUrl(serviceUri);

            if (AuthService.isLoggedIn()) {
                api.authorizeApi();

                if (enrichTokenOnInit) {
                    await api.enrichToken();
                }
            }

            setRequest(api);

            const { cache, scheme: serviceScheme } = await initCacheManager(api);

            const apolloClient = new ApolloClient({
                link: ApolloLink.from([
                    onError(({ graphQLErrors, networkError }) => {
                        if (graphQLErrors && !validationErrors({ graphQLErrors } as any)) {
                            const errorMessage = `${graphQLErrors[0].message}. ${
                                // @ts-ignore
                                graphQLErrors[0].debugMessage || ''
                            }`;

                            message.error(errorMessage);
                        }

                        if (networkError) {
                            message.error(`${networkError.message}. ${networkError.name || ''}`);
                        }
                    }),
                    createUploadLink({
                        uri: serviceUri,
                        // @ts-ignore
                        fetch: buildAxiosFetch(api.fetch, function (config: AxiosRequestConfig) {
                            config.validateStatus = Axios.defaults.validateStatus;
                            return config;
                        }),
                    }),
                ]),
                cache,
            });

            // @ts-ignore
            schemas[serviceUri] = serviceScheme;
            clients[serviceUri] = apolloClient;

            setClient(apolloClient);
            requests[serviceUri] = api;
        } catch (e) {
            console.log(e);
        }
    };

    React.useEffect(() => {
        if (client && (enableScheme === false || scheme)) {
            return;
        }

        initApolloClient();
    }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

    // @ts-ignore
    const context = React.useMemo<NetworkContextProps>(() => {
        return {
            request: request!,
            service,
            scheme,
            clients,
            createAssetUrl: createAssetUrl(service),
        };
    }, [request, service, scheme]);

    if (!client || (enableScheme && !scheme)) {
        if (loader) return loader();

        return <Loader loading={true} />;
    }

    return (
        <ApolloProvider client={client}>
            <NetworkContext.Provider value={context}>{children}</NetworkContext.Provider>
        </ApolloProvider>
    );
};

export default NetworkLayer;
