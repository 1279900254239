import React from 'react';

import classNames from 'classnames';
import { Typography } from 'antd';

import View from '../../../../../../aqua-delivery-web-client-ui/components/View';

type LegendRowProps = {
    color: string,
    text: string,
    serieName: string,
}

export const LegendRow: React.FC<LegendRowProps> = ({ color, text, serieName }) => {
    return (
        <>
            <View className={classNames('slice-tooltip-legend-circle')} style={{ backgroundColor: color }}></View>
            <Typography.Text style={{ fontWeight: 'normal' }}>{serieName}</Typography.Text>
            <Typography.Text style={{ fontWeight: 'normal', marginLeft: 14, justifySelf: 'end' }}>{text}</Typography.Text>
        </>
    );
}

const SliceTooltip: React.FC<{ title: string }> = ({ children, title }) => (
    <View className={'graph-tooltip graph-tooltip-slice'} flexDirection="column">
        <Typography.Text className="slice-tooltip-legend-title">{title}</Typography.Text>
        <View className="slice-tooltip-legend-items">
            {children}
        </View>
    </View>
);

export default SliceTooltip;