import { FieldRenderProps } from 'react-final-form';
import { Dayjs } from 'dayjs';

export type TFieldRenderProps<Value> = FieldRenderProps<Value, any>;

export type Period = {
    from: Dayjs;
    to: Dayjs;
};

export enum NamedPeriod {
    Days = 'days',
    Weeks = 'weeks',
    Months = 'months',
}
