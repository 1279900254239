export default {
    title: 'Кабинет компании',
    tabs: {
        companyProfile: 'Профиль компании',
        pricingPlans: 'Тарифы',
        integrations: 'Интеграции',
    },
    companyProfile: {
        fields: {
            companyName: {
                label: 'Название компании',
                placeholder: 'Введите название компании',
                validations: {
                    require: 'Поле обязательно для заполнения',
                },
            },
            companyLogo: {
                label: 'Логотип',
                validations: {
                    image: 'Логотип должен быть изображением',
                },
            },
            companyLocations: {
                label: 'Города:',
            },
            newCity: {
                label: 'Добавить город',
                validations: {
                    require: 'Выберите своё местоположение из списка',
                },
            },
            itn: {
                label: 'ИНН',
                placeholder: 'Введите ИНН',
                validators: {
                    notValid: 'ИНН не валиден',
                },
            },
        },
        submitButton: 'Сохранить',
    },
    plans: {
        pricesTable: {
            title: 'Тарифный план — ',
        },
        fields: {
            pricingPlanDuration: 'Продолжительность',
            pricingPlanMonthlyPrice: 'Цена в месяц',
            pricingPlanPrice: 'Сумма',
        },
        tariffDocumentName: 'Тарифные планы.pdf',
        yourPlan: 'ваш тариф',
        changePlan: 'Для смены тарифного плана вы можете связаться с нами по номеру',
    },
    integrations: {
        amo: {
            title: 'AmoCRM',
            amoButtonTitle: 'Подключить amoCRM',
            amoPopupTitle: 'Предоставить доступ для интеграции',
            connected: 'Интеграция подключена',
        },
    },
    messages: {
        successPricingPlanUpdate: 'Ваш тарифный план успешно изменён',
        successCompanyLogoUpdate: 'Логотип вашей компании успешно изменён',
        successCompanyNameUpdate: 'Название вашей компании было успешно обновлено',
        successCompanyLocationsUpdate: 'Список городов был успешно изменён',
        successCompanyLocationCreate: 'Город был успешно добавлен',
        successCompanyItnUpdate: 'ИНН был успешно обновлён',
    },
};
