import React, { useContext } from "react";

import { SliceTooltipProps } from "@nivo/line";

import { RegistryContext } from "../../../Registry";
import useLocalTranslation from "../../../../../../../hooks/useLocalTranslation";
import SliceTooltip, { LegendRow } from "../../base-graph/SliceTooltip";

const FillingsGraphTooltip = (props: SliceTooltipProps) => {
    const { dayjs } = useContext(RegistryContext);

    const [liters] = useLocalTranslation(
        (t) => t.vending,
        t => [t.liters],
    );

    const title = dayjs(props.slice.points[0].data.x).format('DD.MM.YYYY, HH:mm');

    return (
        <SliceTooltip title={title}>
            {
                props.slice.points.map(point => {
                    const color = point.serieColor;
                    const text = `${point.data.y} ${liters}`;

                    return (
                        <LegendRow
                            color={color}
                            text={text}
                            key={point.id}
                            serieName={point.serieId.toString()}
                        />
                    )
                })
            }
        </SliceTooltip>
    );
}

export default FillingsGraphTooltip;