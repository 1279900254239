import React, { FC } from 'react';

import { Row, Col, Card, Statistic } from 'antd';

import Section from '../../section/Section';
import SectionHeader from '../../section/SectionHeader';
import MobileFillingsGraph from './MobileFillingsGraph';
import useLocalTranslation from '../../../../../../../hooks/useLocalTranslation';

type IndicatorsProps = {
    totalVolume: number;
    mobileVolume: number;
};

const Indicators: FC<IndicatorsProps> = ({ totalVolume, mobileVolume }) => {
    const [liters] = useLocalTranslation(
        t => t.vending,
        t => [t.liters],
    );

    const parameters = useLocalTranslation(
        t => t.vending.fillingsGraph.parameters,
        t => t,
    );

    return (
        <Section>
            <SectionHeader.Sub title={parameters.title} style={{ marginTop: 18 }} />
            <Row gutter={[10, 10]}>
                <Col>
                    <Card className="indicator-card">
                        <Statistic
                            title={parameters.totalFillings}
                            value={totalVolume}
                            precision={3}
                            suffix={liters}
                        />
                    </Card>
                </Col>
                <Col>
                    <Card className="indicator-card">
                        <Statistic
                            title={parameters.mobileFillings}
                            value={mobileVolume}
                            precision={3}
                            suffix={liters}
                        />
                        <MobileFillingsGraph
                            totalVolume={totalVolume}
                            mobileVolume={mobileVolume}
                        />
                    </Card>
                </Col>
            </Row>
        </Section>
    );
};

export default Indicators;
