import React, { FC } from "react";

import { ResponsivePie } from "@nivo/pie";

import { roundNumber } from "../../../../../../../aqua-delivery-client-graphql/utils";
import View from "../../../../../../../aqua-delivery-web-client-ui/components/View";

import nivoTheme from "../../base-graph/theme";
import PercentLayer from "./layers/PercentLayer";

export type PieDatum = {
    id: string;
    value: number;
    hiddenLabel?: boolean;
    mainValue?: boolean;
}

export type MobileFillingsGraphProps = {
    totalVolume: number;
    mobileVolume: number;
}

const MobileFillingsGraph: FC<MobileFillingsGraphProps> = ({ totalVolume, mobileVolume  }) => {
    return (
        <View style={{ width: 120, height: 120, marginLeft: 16 }}>
            <ResponsivePie<PieDatum>
                data={[
                    {
                        id: "mobile",
                        value: totalVolume ? roundNumber(mobileVolume / totalVolume * 100, 1) : 0,
                        hiddenLabel: true,
                        mainValue: true,
                    },
                    {
                        id: "total",
                        value: totalVolume ? roundNumber((totalVolume - mobileVolume) / totalVolume * 100, 1) : 100,
                        hiddenLabel: true,
                    }
                ]}
                innerRadius={0.7}
                enableRadialLabels={false}
                colors={['#1CBF2F', '#EFEFEF']}
                sliceLabelsTextColor={{ from: 'labelColor' }}
                sliceLabel={item => item.data.hiddenLabel ? '' : `${item.value}%`}
                layers={[
                    'slices',
                    'radialLabels',
                    'sliceLabels',
                    'legends',
                    PercentLayer,
                ]}
                theme={nivoTheme}
            />
        </View>
    )
}

export default MobileFillingsGraph;