import React from 'react';

import { ApolloClient, NormalizedCacheObject } from 'apollo-boost';

import Scheme from '../../../aqua-delivery-client-graphql/Scheme';
import { ServiceName } from '../../../config';
import { TFetch } from '../../customFetch';

export type NetworkContextProps = {
    request: TFetch | null;
    service: ServiceName;
    scheme: Scheme;
    createAssetUrl: (uri: string) => string;
    clients: Record<string, ApolloClient<NormalizedCacheObject>>;
};

export const NetworkContext = React.createContext<NetworkContextProps>({
    request: null,
    service: 'auth',
    scheme: new Scheme(),
    createAssetUrl: (uri: string) => '',
    //@ts-ignore,
    clients: {},
});
