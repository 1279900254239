type VendingTelemetry = {
    waterLevel: null
        | {
            value: number | null,
            criticalLevel: number,
        },
}

export enum VendingTelemetryStatus {
    NonActive = 'non-active',
    Active = 'active',
    Critical = 'critical',
}

const VendingTelemetryUtils = {
    isCriticalLevel: (value: number, criticalLevel: number) => value < criticalLevel,
    hasCriticalTelemetry: ({ waterLevel }: VendingTelemetry) => {
        return waterLevel && waterLevel.value !== null
            ? VendingTelemetryUtils.isCriticalLevel(waterLevel.value, waterLevel.criticalLevel)
            : false;
    },
    getVendingStatus: (active: boolean, telemetry: VendingTelemetry): VendingTelemetryStatus => {
        if (!active) return VendingTelemetryStatus.NonActive;
        if (VendingTelemetryUtils.hasCriticalTelemetry(telemetry)) return VendingTelemetryStatus.Critical;

        return VendingTelemetryStatus.Active;
    },
}

export default VendingTelemetryUtils;