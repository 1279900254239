export default {
    title: "Registration",
    result: {
        title: "You have registered in the system",
        message: "We have sent You an email ({{email}}), please open it and follow the instructions.",
        spam: 'Please, if you have not received email within five minutes, check the Spam folder - it may have got there by mistake.'
    },
    form: {
        fields: {
            firstName: {
                placeholder: "Your name",
               label: "Your name:",
                validations: {
                    require: "Field is required"
                }
            },
            email: {
                placeholder: "E-mail",
               label: "E-mail:",
                validations: {
                    require: "Field is required",
                    email: "You specified an invalid email address"
                }
            },
            phoneNumber: {
                placeholder: "Phone",
               label: "Phone:",
                validations: {
                    require: "Field is required"
                }
            },
            agreement: {
                label: "I agree with the privacy policy",
                validations: {
                    require: "Your consent is required"
                }
            },
        },
        labels: {
            agreement: 'By Clicking «Send» You agree to'
        },
        links: {
            agreement: "privacy policy"
        },
        submitButton: "Submit",
        alreadyHaveAccount: 'I already have an account',
    }
}