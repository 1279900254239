import { StepName } from "../../routes/partner-cabinet/routes/finish-registration/components/stepperHelper/StepNameEnum";

export default {
    buttons: {
        next: "Next",
        complete: "Complete"
    },
    pricingPlans: {
        title: "Thank You",
        title2: "for registering!",
        subtitle: "Choose your plan",
        pricesTable: {
            title: 'Pricing plan — ',
        },
        messages: {
            notSelectedPricingPlan: "Choose a pricing plan"
        },
        labels: {
            pricingPlanDuration: 'Period',
            pricingPlanMonthlyPrice: 'Monthly payment',
            pricingPlanPrice: 'Price',
            launchAmount: "Launch price",
            pricingPlanActions: "Choose",
        }
    },
    payment: {
        title: "Are you ready to start? Let's make a payment!",
        card: {
            bankCard: {
                title: "Online payment",
                content: "Pay online",
            },
            contract: {
                title: "Requisites",
                content: "Agreement ",
            }
        },
        labels: {
            oferta: "Oferta Agreement",
            paymentMethodsNotAvailable: "Unfortunately, none of the existing payment methods are available to you.",
            callUs: "Contact us by phone:",
        },
        fields: {
            file: {
                label: "Upload the requisites",
                validators: {
                    require: "This field is required"
                }
            }
        },
        buttons: {
            download: "Download",
            back: "Back to pricing plans"
        },
        tooltips: {
            payWithOnlinePayment: 'Choose one of the available payment methods',
        },
    },
    company: {
        title: "Tell us",
        title2: "about your company",
        subtitle: `We need more information
        about your company for an individual app 
        and  sales website`,
        buttons: {
            save: "Save",
            next: "Next",
            skip: "Skip",
            back: "Back"
        },
        navigation: {
            about: "About the company",
            contacts: "Company contacts",
            dispatchers: "Operator",
            links: "Links",
            paymmentMethods: "How will your customers pay?",
            details: "Company details",
            assets: "Brand design",
        },
        steps: {
            main: {
                title: "Company information",
                fields: {
                    brandName: {
                        label: "Brand Name",
                        validators: {
                            require: "please, fill it"
                        }
                    },
                    memo: {
                        label: "This text will be on the sales site and in the client application",
                        placeholder: "Tell us what year you have been on the market, what are your advantages, what is your product range",
                        validators: {
                            require: "please, fill it"
                        }
                    }
                }
            },
            contacts: {
                title: "Company contacts",
                fields: {
                    directorPhoneNumber: {
                        label: "Manager's Phone number",
                        validators: {
                            require: "please, fill it"
                        }
                    },
                    directorEmail: {
                        label: "Manager's E-mail",
                        validators: {
                            require: "please, fill it",
                            email: "It looks Like you entered an invalid email address"
                        }
                    },
                    companyPhoneNumber: {
                        label: "Company Phone",
                        validators: {
                            require: "please, fill it"
                        }
                    },
                    companyEmail: {
                        label: "Company's E-mail",
                        validators: {
                            require: "please, fill it",
                            email: "It looks Like you entered an invalid email address"
                        }
                    },
                    pickupLocation: {
                        label: "Pickup address",
                    }
                }
            },
            dispatchers: {
                title: "Operator",
                fields: {
                    dispatcherPhoneNumber: {
                        label: "Operator's Phone number",
                        validators: {
                            require: "please, fill it"
                        }
                    },
                    dispatcherEmail: {
                        label: "Operator's E-mail",
                        validators: {
                            require: "please, fill it",
                            email: "It looks Like you entered an invalid email address"
                        }
                    },
                }
            },
            links: {
                title: "Links to social networks",
                fields: {
                    site: { label: "Website", check: "we don't have any" },
                    vkLink: { label: "Social Network page", check: "we don't have any" },
                    instagramLink: { label: "Instagram", check: "we don't have Instagram" },
                    misc: { label: "Other sources" },
                }
            },
            paymentMethods: {
                title: "How will your customers pay?",
                fields: {
                    onlinePayment: {
                        notSelected: "Not selected",
                    },
                    other: {
                        label: "Other option"
                    }
                }
            },
            assets: {
                title: "Upload your logo and brand book (if available)",
                labels: {
                    sendEmail: "Or send data to email",
                    sendEmail2: "In the subject line, write the city you are from and the name of your company / brand"
                }
            },
            details: {
                title: "Upload your company details",
                fields: {
                    companyDetails: {
                        validators: {
                            require: 'Company details are required for uploading'
                        }
                    }
                }
            },
        }
    },
    partnerFinish: {
        title: 'Thank you for registering!',
        discription:
            'In the near future we will contact you, ask a few clarifying questions and start building your application.',
    },
    stepper: {
        [StepName.pricingPlan]: "Pricing Plan",
        [StepName.payment]: "Payment",
        [StepName.companyInfo]: "About company",
    }
}