export default {
    title: 'Отчет по отправленным SMS',
    table: {
        phone: 'Номер телефона',
        message: 'Сообщение',
        cost: 'Стоимость',
        createdAt: 'Создано',
        deliveryDate: 'Доставлено',
        type: 'Тип',
        totalCost: 'Общая стоимость',
        reportDownload: 'Скачать отчет за выбранный период',
    },
    type: {
        authorization: 'Авторизация',
        marketing: 'Маркетинг',
    },
    selector: {
        prefix: 'Тип сообщения:',
        notSelected: 'не выбран',
    },
};
