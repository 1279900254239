import React, { CSSProperties, useMemo } from 'react';

import { Typography, Space } from 'antd';

import BackSVG from '../../assets/svg/arrow2.svg';

import SVG from '../SVG';
import View from '../View';
import useWindowSize from '../../hooks/useWindowSize';

import './styles/index.less';

export type TitleProps = {
    title: string | React.ReactElement | undefined;
    onBackButtonClick?: () => Promise<void>;
    left?: React.ReactElement;
    right?: React.ReactElement;
    titleStyle?: CSSProperties;
    containerStyle?: CSSProperties;
};

const BackButton: React.FC<{ onClick?: () => Promise<void>; style?: CSSProperties }> = ({
    onClick,
    style,
}) => {
    return (
        <div className="page-title__back-button" onClick={onClick}>
            <SVG source={BackSVG} style={style} defaultStroke />
        </div>
    );
};

const Title: React.FC<TitleProps> = ({
    title,
    onBackButtonClick,
    left,
    right,
    titleStyle,
    containerStyle,
}) => {
    const { isMobile } = useWindowSize();

    const isBackButtonVisible = !!onBackButtonClick;

    const titleContainerStyle = useMemo(
        () => ({
            display: 'flex',
            alignItems: 'center',
            ...containerStyle,
        }),
        [containerStyle],
    );

    return (
        <View alignItems="center" className="page-title" style={titleContainerStyle}>
            <Space>
                <Typography.Title
                    style={titleStyle}
                    className="page-title__title"
                    level={isMobile ? 2 : 1}
                    ellipsis
                >
                    {isBackButtonVisible && <BackButton onClick={onBackButtonClick} />}

                    {title}
                </Typography.Title>

                <View alignItems="center">{left}</View>
            </Space>

            <View alignItems="center" style={{ marginLeft: 'auto' }}>
                {right}
            </View>
        </View>
    );
};

export default Title;
