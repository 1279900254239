
export default {
    title: "Регистрация",
    result: {
        title: "Вы зарегистрировались в системе",
        message: "Мы отправили Вам письмо на почту ({{email}}), пожалуйста откройте его и следуйте инструкциям.",
        spam: 'Пожалуйста, если вы не получили письмо в течение пяти минут, то проверьте папку "Спам" - возможно, оно попало туда по ошибке.'
    },
    form: {
        fields: {
            firstName: {
                placeholder: "Ваше имя",
                label: "Ваше имя:",
                validations: {
                    require: "Поле обязательно для заполнения"
                }
            },
            email: {
                placeholder: "E-mail",
                label: "E-mail:",
                validations: {
                    require: "Поле обязательно для заполнения",
                    email: "Вы указали невалидный E-mail"
                }
            },
            phoneNumber: {
                placeholder: "Телефон",
                label: "Телефон:",
                validations: {
                    require: "Поле обязательно для заполнения"
                }
            },
            agreement: {
                label: "I agree with the privacy policy",
                validations: {
                    require: "Your consent is required"
                }
            },
        },
        labels: {
            agreement: "Нажимая кнопку «Отправить» Вы соглашаетесь с"
        },
        links: {
            agreement: "политикой конфиденциальности"
        },
        submitButton: "Отправить",
        alreadyHaveAccount: 'У меня уже есть аккаунт',
    }
}