import { FillingGraphLegends } from '../../routes/partner-cabinet/routes/vending/components/graphs/FillingsGraph/FillingsGraph';
import { VendingTelemetryStatus } from '../../routes/partner-cabinet/routes/vending/utils/vendingTelemetryUtils';
import { NamedPeriod } from '../../types';

export default {
    title: 'Vendings',
    liters: 'ltr.',
    meters: 'm.',
    registry: {
        timezoneTitle: 'Vending timezone',
        timezoneHelper: 'The time zone of the vending is used to display the time on the charts',
    },
    table: {
        columns: {
            address: 'Address',
            temperature: 'Temperature, ℃',
            waterLevel: 'Water level, m.',
            lastActivity: 'Latest activity',
            timezone: 'Vending timezone',
        },
        timezoneHelper: 'Time is displayed in the vending timezone',
        onTheMap: 'On the map',
    },
    waterVolume: {
        title: 'Water level',
    },
    temperature: {
        title: 'Vending temperature',
    },
    fillings: {
        title: 'Fillings',
    },
    temperatureGraph: {
        title: 'Vending temperature dynamics',
        legend: 'Temperature, ℃',
    },
    waterVolumeGraph: {
        title: 'Water level dynamics',
        legend: 'Water level, m.',
    },
    fillingsGraph: {
        title: 'Filling dynamics',
        legend: 'Volume, ltr.',
        legends: {
            [FillingGraphLegends.TotalVolume]: 'Total volume',
            [FillingGraphLegends.MobileAppVolume]: 'Mobile app',
        },
        parameters: {
            title: 'Indicators for the period',
            totalFillings: 'Total volume of fillings',
            mobileFillings: 'Through the app',
        },
    },
    periodSwitcher: {
        [NamedPeriod.Days]: 'Day',
        [NamedPeriod.Weeks]: 'Week',
        [NamedPeriod.Months]: 'Month',
    },
    datePicker: {
        from: 'Beginning of period',
        to: 'End of period',
    },
    vendingPopup: {
        temperature: 'Temperature',
        waterLevel: 'Water level',
        lastActivity: 'Latest activity',
        liters: 'ltr.',
        meters: 'm.',
        goToVendingScreen: 'Go to the vending screen',
        timezone: 'Timezone',
    },
    colorLegend: {
        [VendingTelemetryStatus.Active]: 'Working',
        [VendingTelemetryStatus.NonActive]: 'Not working',
        [VendingTelemetryStatus.Critical]: 'Critical state',
        title: 'Vending state',
    },
};
