import useTranslation, {
    PathsReturnType,
    Paths,
} from '../aqua-delivery-web-client-ui/hooks/useTranslation';
import { translateMap } from '../translations';

type Translation = typeof translateMap;

const useLocalTranslation = <
    T extends (args: Translation) => Record<any, any>,
    TR extends ReturnType<T>,
    P extends (translations: TR) => Paths,
    R extends PathsReturnType<ReturnType<P>>
>(
    root: T,
    paths: P,
) => {
    return useTranslation(paths(root(translateMap))) as R;
};

export default useLocalTranslation;
