import React, { useCallback } from 'react';

import { When } from 'react-if';
import { Col, Row } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import DateRangeInput, { DateTimePeriodValues } from '../../../../../../aqua-delivery-web-client-ui/components/ui/inputs/DateRangeInput';
import useLocalTranslation from '../../../../../../hooks/useLocalTranslation';

import PeriodSwitcher, { PeriodButton } from '../PeriodSwitcher';
import { DatePeriodState, getNamedPeriodDates } from '../../hooks/usePeriod';
import SectionHeader, { SectionHeaderProps } from './SectionHeader';
import { NamedPeriod } from '../../../../../../types';

type PeriodSectionHeaderProps = SectionHeaderProps & {
    setPeriod?: React.Dispatch<React.SetStateAction<DatePeriodState>>;
    period?: DatePeriodState;
    disableSwitcher?: boolean;
    timezone?: number;
};

const PeriodSectionHeader: React.FC<PeriodSectionHeaderProps> = ({
    setPeriod,
    period,
    disableSwitcher,
    timezone,
    ...other
}) => {
    const onPeriodChange = useCallback(
        (namedPeriod: NamedPeriod) => {
            const { from, to } = getNamedPeriodDates(namedPeriod, timezone);

            setPeriod && setPeriod({ from, to, namedPeriod, useNamedPeriod: true });
        },
        [setPeriod, timezone],
    );

    const onDatepickerChange = useCallback(
        (values: [Dayjs | null, Dayjs | null]) => {
            const tz = timezone || dayjs().utcOffset();

            const [from, to] = values;

            setPeriod &&
                values[0] &&
                values[1] &&
                setPeriod({
                    from: (from || dayjs()).clone().utcOffset(tz),
                    to: (to || dayjs()).clone().utcOffset(tz),
                    namedPeriod: null,
                    useNamedPeriod: false,
                });
        },
        [setPeriod, timezone],
    );

    const periods = useLocalTranslation(
        t => t.vending.periodSwitcher,
        t => t,
    );

    const { from, to } = useLocalTranslation(
        t => t.vending.datePicker,
        t => t,
    );

    return (
        <SectionHeader {...other}>
            <Row gutter={[12, 12]}>
                <Col flex={1}>
                    <DateRangeInput
                        onChange={onDatepickerChange}
                        format={'DD MMMM, YYYY'}
                        startDatePickerProps={{
                            value: period ? period.from : dayjs(),
                            allowClear: false,
                        }}
                        endDatePickerProps={{
                            value: period ? period.to : dayjs(),
                            allowClear: false,
                        }}
                        placeholderFrom={from}
                        placeholderTo={to}
                        className={'graph-header-datepicker'}
                        noFutureDate
                    />
                </Col>
                <When condition={!disableSwitcher}>
                    <Col flex={1}>
                        <PeriodSwitcher
                            onChange={e => onPeriodChange(e.target.value)}
                            value={period?.namedPeriod}
                        >
                            <PeriodButton
                                caption={periods[NamedPeriod.Days]}
                                value={NamedPeriod.Days}
                            />
                            <PeriodButton
                                caption={periods[NamedPeriod.Weeks]}
                                value={NamedPeriod.Weeks}
                            />
                            <PeriodButton
                                caption={periods[NamedPeriod.Months]}
                                value={NamedPeriod.Months}
                            />
                        </PeriodSwitcher>
                    </Col>
                </When>
            </Row>
        </SectionHeader>
    );
};

export default PeriodSectionHeader;
