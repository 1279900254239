import React, { memo, useMemo } from 'react';

import { ResponsiveLine, Serie, LineProps } from '@nivo/line';
import { AxisProps } from '@nivo/axes';
import { Scale } from '@nivo/scales';

import useWindowSize from '../../../../../../aqua-delivery-web-client-ui/hooks/useWindowSize';

import nivoTheme from './theme';

type BaseLineGraph = Partial<LineProps> & {
    data: Serie[];
    min?: number;
    max?: number;
    axisBottomProps?: Partial<LineProps['axisBottom']>;
    axisLeftProps?: Partial<LineProps['axisLeft']>;
};

const colors = ['#4671FF', '#FF1C1C', '#1CBF2F'];
const xScale: Scale = { type: 'time' };
const graphMargin = { top: 20, right: 10, bottom: 40, left: 70 };
const pointBorderColor = { from: 'serieColor' };

const BaseLineGraph: React.FC<BaseLineGraph> = memo(
    ({ data, min, max, axisBottomProps, axisLeftProps, ...other }) => {
        const { isMobile } = useWindowSize();

        const axisBottomTickMobileStyle = useMemo(
            () => (isMobile ? { tickPadding: 6, tickRotation: -45 } : { tickPadding: 2 }),
            [isMobile],
        );

        const minMax: { min: 'auto' | number; max: 'auto' | number } = useMemo(
            () => ({
                min: min !== undefined ? min : 'auto',
                max: max !== undefined ? max : 'auto',
            }),
            [max, min],
        );

        const yScale: Scale = useMemo(() => ({ type: 'linear', ...minMax }), [minMax]);

        const axisBottom: AxisProps = useMemo(
            () => ({
                orient: 'bottom',
                tickSize: 4,
                ...axisBottomTickMobileStyle,
                ...axisBottomProps,
            }),
            [axisBottomProps, axisBottomTickMobileStyle],
        );

        const axisLeft: AxisProps = useMemo(
            () => ({
                orient: 'left',
                tickSize: 0,
                tickPadding: 16,
                legendOffset: -60,
                legendPosition: 'start',
                ...axisLeftProps,
            }),
            [axisLeftProps],
        );

        return (
            <ResponsiveLine
                defs={[
                    {
                        id: 'negativeGradient',
                        type: 'linearGradient',
                        colors: [
                            { offset: 0, color: 'transparent' },
                            { offset: 100, color: 'inherit' },
                        ],
                    },
                    {
                        id: 'positiveGradient',
                        type: 'linearGradient',
                        colors: [
                            { offset: 0, color: 'inherit' },
                            { offset: 100, color: 'transparent' },
                        ],
                    },
                ]}
                data={data}
                margin={graphMargin}
                xScale={xScale}
                yScale={yScale}
                axisTop={null}
                axisRight={null}
                axisBottom={axisBottom}
                enableGridX={false}
                axisLeft={axisLeft}
                pointBorderWidth={2}
                pointColor="white"
                pointBorderColor={pointBorderColor}
                pointSize={6}
                lineWidth={2}
                pointLabelYOffset={-12}
                crosshairType={'bottom'}
                colors={colors}
                theme={nivoTheme}
                // @ts-ignore
                motionConfig={'stiff'}
                enableArea
                areaOpacity={0.1}
                fill={[
                    {
                        match: item => item.id === 'positive',
                        id: 'positiveGradient',
                    },
                    {
                        match: item => item.id === 'negative',
                        id: 'negativeGradient',
                    },
                ]}
                useMesh
                {...other}
            />
        );
    },
);

export default BaseLineGraph;
