import React from 'react';

import { Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import View from '../../View';
import { setEndOfDay } from '../../../Utils';
import DatePicker, { DatePickerProps } from '../DatePicker';
import useAntdLocale from '../../../hooks/useAntdLocale';

type DateRangeInputProps = {
    format: string;
    onChange: (values: DateTimePeriodValues) => void;
    defaultStart?: Dayjs | null;
    defaultEnd?: Dayjs | null;
    className?: string;
    placeholderFrom?: string;
    placeholderTo?: string;
    startDatePickerProps?: DatePickerProps;
    endDatePickerProps?: DatePickerProps;
    containerStyles?: React.CSSProperties;
    noFutureDate?: boolean;
    strictDateMode?: boolean;
};

export type DateTimePeriodValues = [Dayjs | null, Dayjs | null];

const DateRangeInput: React.FC<DateRangeInputProps> = props => {
    const {
        className,
        format,
        defaultStart,
        defaultEnd,
        placeholderFrom,
        placeholderTo,
        startDatePickerProps,
        endDatePickerProps,
        containerStyles,
        noFutureDate = false,
        strictDateMode = false,
        ...other
    } = props;

    const [startDate, setStartDate] = React.useState(
        defaultStart || startDatePickerProps?.value || null,
    );
    const [endDate, setEndDate] = React.useState(defaultEnd || endDatePickerProps?.value || null);
    const [endOpen, setEndOpen] = React.useState(false);

    const { antdLocale } = useAntdLocale();

    React.useEffect(() => {
        const startDateNewValue = startDatePickerProps?.value || null;
        const endDateNewValue = endDatePickerProps?.value || null;

        setStartDate(startDateNewValue);
        setEndDate(endDateNewValue);
    }, [endDatePickerProps, startDatePickerProps]);

    const disabledStartDate = (startValue: Dayjs | null) => {
        if (!startValue || !endDate) {
            return false;
        }

        const endValueUnixTime = endDate.unix();
        const startValueUnixTime = startValue.unix();

        return (
            startValueUnixTime > endValueUnixTime ||
            (strictDateMode && endValueUnixTime === startValueUnixTime)
        );
    };

    const disabledEndDate = (endValue: Dayjs | null) => {
        if (!endValue || !startDate) {
            return false;
        }

        const endValueUnixTime = endValue.unix();
        const startValueUnixTime = startDate.unix();

        return (
            endValueUnixTime < startValueUnixTime ||
            (noFutureDate && endValueUnixTime > setEndOfDay(dayjs()).unix()) ||
            (strictDateMode && endValueUnixTime === startValueUnixTime)
        );
    };

    const onStartChange = (value: Dayjs | null) => {
        setStartDate(value);
        //@ts-ignore
        other.onChange([value, endDate]);
    };

    const onEndChange = (value: Dayjs | null) => {
        setEndDate(value);
        //@ts-ignore
        other.onChange([startDate, value]);
    };

    const handleStartOpenChange = (open: boolean) => {
        if (!open) {
            setEndOpen(true);
        }
    };

    const handleEndOpenChange = (open: boolean) => {
        setEndOpen(open);
    };

    return (
        <View alignItems="center" style={containerStyles}>
            <DatePicker
                disabledDate={disabledStartDate}
                format={format}
                value={startDate}
                placeholder={placeholderFrom}
                onChange={onStartChange}
                onOpenChange={handleStartOpenChange}
                style={{ minWidth: 'auto', flex: 1 }}
                className={className}
                showToday={false}
                locale={antdLocale.DatePicker}
                {...startDatePickerProps}
            />
            <Typography.Text style={{ padding: '0 10px' }} className="hyphen">
                –
            </Typography.Text>
            <DatePicker
                disabledDate={disabledEndDate}
                format={format}
                value={endDate}
                placeholder={placeholderTo}
                onChange={onEndChange}
                open={endOpen}
                onOpenChange={handleEndOpenChange}
                style={{ minWidth: 'auto', flex: 1 }}
                className={className}
                disabled={!startDate}
                locale={antdLocale.DatePicker}
                {...endDatePickerProps}
            />
        </View>
    );
};

export default DateRangeInput;
