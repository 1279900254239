import { Dayjs } from 'dayjs';
import generatePicker, {
    PickerDateProps,
    PickerTimeProps,
    RangePickerBaseProps,
} from 'antd/lib/date-picker/generatePicker';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';

import 'antd/es/date-picker/style/index';

export type DatePickerProps = PickerDateProps<Dayjs>;
export type TimePickerProps = PickerTimeProps<Dayjs>;
export type RangePickerProps = Omit<RangePickerBaseProps<Dayjs>, 'picker'>;

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

export default DatePicker;
