export default {
    title: 'История баланса',
    labels: {
        balance: 'Баланс',
        totalPay: 'Сумма к оплате',
        debit: 'Пополнения',
        paymentMethod: 'Выберите способ оплаты',
    },
    buttons: {
        deposit: 'Пополнить баланс',
        deposit2: 'Пополнить',
    },
};
