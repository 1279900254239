export default {
    title: 'Balance history',
    labels: {
        balance: 'Balance',
        totalPay: 'Amount to be paid',
        debit: 'Debit',
        paymentMethod: 'Choose payment method',
    },
    buttons: {
        deposit: 'Add funds',
        deposit2: 'Add',
    },
};
