import React from 'react';

import View from '../../../../../../aqua-delivery-web-client-ui/components/View';

type SectionBodyProps = {
    containerStyle?: React.CSSProperties,
    className?: string,
}

const SectionBody: React.FC<SectionBodyProps> = ({ children, containerStyle, className }) => {
    return (
        <View style={{ ...containerStyle }} flexDirection="column" className={className}>
            {children}
        </View>
    )
}

export default SectionBody;