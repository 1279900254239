export default {
    title: 'Восстановление пароля',
    form: {
        fields: {
            phone: {
                label: 'Телефон',
                validations: {
                    require: 'Поле обязательно для заполнения',
                },
            },
            verificationCode: {
                label: 'Последние {{codeLength}} цифры номера',
                validations: {
                    require: 'Поле обязательно для заполнения',
                },
            },
            password: {
                label: 'Новый пароль',
                validations: {
                    require: 'Поле обязательно для заполнения',
                    repeat: 'Пароли должны совпадать',
                },
            },
            repeatPassword: {
                label: 'Повторите новый пароль',
                validations: {
                    require: 'Поле обязательно для заполнения',
                    repeat: 'Пароли должны совпадать',
                },
            },
        },
        submitButton: 'Войти',
    },
    labels: {
        phoneStep:
            'Введите номер телефона, на него поступит входящий звонок, отвечать на него не нужно',
        verificationStep:
            'Вам поступит входящий звонок на номер {{phoneNumber}}, введите последние {{codeLength}} цифры входящего номера',
        createPasswordStep: 'Отлично :) Теперь введите новый пароль',
    },
    buttons: {
        sendCode: 'Отправить',
        createPassword: 'Сменить пароль',
        repeatVerification: 'Я не получил код :(',
    },
    messages: {
        successResetPassword: `Пароль успешно изменён`,
        requestResetPassword: `Код повторно отправлен на номер {{phoneNumber}}`,
    },
};
