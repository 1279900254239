import dayjs from 'dayjs';

import { convertDateToServerFormat } from '../../../../../aqua-delivery-client-graphql/utils';
import { DatePeriodState, getNamedPeriodDates } from '../hooks/usePeriod';
import { setEndOfDay, setStartOfDay } from '../../../../../aqua-delivery-web-client-ui/Utils';
import { Period } from '../../../../../types';

export type LineChartData = { value: number | null; createdAt: string };

export const formatChartDataPeriod = (period: Period) => {
    const { from, to } = period;

    const defaultTimeFormatPattern = 'HH:mm';

    return {
        from: convertDateToServerFormat(from, { timeFormat: defaultTimeFormatPattern }),
        to: convertDateToServerFormat(to, { timeFormat: defaultTimeFormatPattern }),
    };
};

export const getChartDataUtcPeriod = (utcPeriod: DatePeriodState, timezone: number = 0) => {
    const { from: currentFrom, to: currentBefore, namedPeriod, useNamedPeriod } = utcPeriod;

    const { from, to } =
        useNamedPeriod && namedPeriod !== null
            ? getNamedPeriodDates(namedPeriod, 0)
            : {
                  from: setStartOfDay(currentFrom).utcOffset(-timezone),
                  to: setEndOfDay(currentBefore).utcOffset(-timezone),
              };

    return { from, to };
};

const isNumbersHasDifferentSign = (a: number, b: number) => {
    return (a > 0 && b < 0) || (a < 0 && b > 0);
};

export const normalizeTimelinePositiveNegativeTransition = (
    data: { x: Date; y: number | null }[],
) => {
    return data.reduce<typeof data[0][]>((values, item, i, arr) => {
        const nextItem = arr[i + 1];

        values.push(item);

        if (
            nextItem !== undefined &&
            item.y !== null &&
            nextItem.y !== null &&
            isNumbersHasDifferentSign(item.y, nextItem.y)
        ) {
            const averageDate = dayjs(nextItem.x)
                .subtract(dayjs(nextItem.x).diff(dayjs(item.x)) / 2, 'milliseconds')
                .toDate();

            values.push({ x: averageDate, y: 0 });
        }

        return values;
    }, []);
};

export const transformLineChartData = <T extends LineChartData>(data: T[]) => {
    return data
        .map(event => {
            return {
                x: dayjs(event.createdAt).toDate(),
                y: event.value,
            };
        })
        .sort((a, b) => dayjs(a.x).unix() - dayjs(b.x).unix());
};

export const transformTimelineChartData = <T extends LineChartData>(data: T[] | undefined) => {
    if (!data || data.length === 0) return { series: [], aggregatedData: [] };

    const chartData = transformLineChartData(data);

    const normalizedData = normalizeTimelinePositiveNegativeTransition(chartData);

    const series = [];

    const positiveValues = normalizedData.map(item => ({
        ...item,
        y: item.y !== null && item.y >= 0 ? item.y : null,
    }));

    const negativeValues = normalizedData.map(item => ({
        ...item,
        y: item.y !== null && item.y <= 0 ? item.y : null,
    }));

    if (positiveValues.filter(item => item.y !== null).length > 0) {
        series.push({
            id: 'positive',
            color: '#4671FF',
            data: positiveValues,
        });
    }

    if (negativeValues.filter(item => item.y !== null).length > 0) {
        series.push({
            id: 'negative',
            color: '#FF1C1C',
            data: negativeValues,
        });
    }

    return {
        series,
        aggregatedData: chartData,
    };
};
