import React from 'react';

import Spin, { SpinProps } from 'antd/lib/spin';

import { LoadingOutlined } from '../../../../../../aqua-delivery-web-client-ui/icons';

const GraphLoader: React.FC<SpinProps> = props => {
    return (
        <Spin className="graph-loader" indicator={<LoadingOutlined />} size="large" {...props} />
    );
};

export default GraphLoader;
