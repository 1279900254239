import camelCase from 'camelcase';
import { Dayjs } from 'dayjs';
import { parsePhoneNumber } from 'libphonenumber-js';
import { unstable_batchedUpdates } from 'react-dom';
import { MappedArrayOfObjects } from './types';

export function copy(text: string) {
    try {
        navigator.clipboard.writeText(text);
    } catch (e) {
        const ta = document.createElement('textarea');
        ta.style.cssText = 'opacity:0; position:fixed; width:1px; height:1px; top:0; left:0;';
        ta.value = text;
        document.body.appendChild(ta);
        ta.focus();
        ta.select();
        document.execCommand('copy');
        ta.remove();
    }
}

export function ucFirst(str: string) {
    if (!str || str.length === 0) return str;
    return str[0].toUpperCase() + str.slice(1);
}

export function lcFirst(str: string) {
    if (!str || str.length === 0) return str;
    return str[0].toLowerCase() + str.slice(1);
}

export function parseCamelCase(value: string) {
    return camelCase(value);
}

export function setNativeProps(id: string, callback: (element: HTMLElement) => void) {
    const element = document.getElementById(id);
    if (element) {
        callback(element);
    }
}

export function setNativePropsByClassName(
    className: string,
    callback: (element: HTMLElement) => void,
) {
    const element = document.getElementsByClassName(className);
    if (element) {
        for (let i = 0; i < element.length; i++) {
            const elm = element.item(i) as HTMLElement;
            elm && callback(elm);
        }
    }
}

export function setNativePropsByTag(className: string, callback: (element: Element) => void) {
    const element = document.getElementsByTagName(className);
    if (element) {
        for (let i = 0; i < element.length; i++) {
            const elm = element.item(i);
            elm && callback(elm);
        }
    }
}

export function createAnimation(options: { prop?: string } = {}) {
    const prop = options.prop || 'all';
    return {
        transition: `${prop} .1s ease-in-out`,
    };
}

export function createCssAnimation(options: { prop?: string } = {}) {
    const prop = options.prop || 'all';
    return `transition: ${prop} .10s ease-in-out`;
}

export const getBase64 = (file: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
};

// for ant upload
export const checkFilesIsImages = (files: File[]) => {
    return files.every(file => !file.type || /^image\/.*/.test(file.type));
};

export const getTimezoneFromCoordinates = (latitude: number) => {
    const timezoneAngle = 15;

    return Math.floor(latitude / timezoneAngle);
};

export const setEndOfDay = (date: Dayjs) => {
    return date.set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 999);
};

export const setStartOfDay = (date: Dayjs) => {
    return date.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
};

export const extractNumbers = (value: string) => value.replace(/[^\d]/g, '');

export const batchedUpdate = unstable_batchedUpdates;

export const transformObjectToKeysArray = <T, K extends string>(
    obj: Record<K, T>,
): MappedArrayOfObjects<typeof obj> =>
    (Object.entries<T>(obj) as any).map((order: [string, any]) => ({ [order[0]]: order[1] }));

export const isDateInPeriod = (
    date: Dayjs,
    startDate: Dayjs,
    maxPeriod: number,
    maxPeriodUnits: Parameters<Dayjs['add']>[1],
) => {
    return Math.abs(date.diff(startDate, maxPeriodUnits)) <= maxPeriod;
};

export const formatPhoneNumber = (text: string) => {
    const internationalFormat = parsePhoneNumber(text)?.formatInternational();
    const [countryCode, areaCode, ...localNumber] = internationalFormat?.split(' ') || [];
    return countryCode + ' (' + areaCode + ') ' + localNumber.join('-');
};
