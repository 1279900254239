import React from 'react';

import NetworkLayer, { NetworkLayerProps } from './NetworkLayer';

export default function <T>(config: NetworkLayerProps) {
    return function (WrappedComponent: any) {
        const Wrapper: React.FC<T> = props => {
            return (
                <NetworkLayer {...config}>
                    <WrappedComponent {...props} />
                </NetworkLayer>
            );
        };
        return Wrapper;
    };
}
