import * as React from 'react';

import { Popover, Typography } from 'antd';

const Help: React.FC<{ content: React.ReactNode }> = ({ content }) => {
    return (
        <Popover content={content}>
            <span
                style={{
                    borderRadius: 12,
                    height: 22,
                    width: 22,
                    backgroundColor: 'white',
                    padding: '0 6px',
                    marginLeft: 4,
                }}
            >
                <Typography.Text className="primary" strong>
                    ?
                </Typography.Text>
            </span>
        </Popover>
    );
};

export default Help;
