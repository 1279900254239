import React from "react";

import { PieLayer } from "@nivo/pie";

import { PieDatum } from "../MobileFillingsGraph";

const PercentLayer: PieLayer<PieDatum> = (props) => {
    const mainValue = props.dataWithArc.find(item => item.data.mainValue);

    return (
        <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            style={{ fontWeight: 600, fontSize: 20 }}
        >
            {(mainValue?.data.value || 0) + '%'}
        </text>
    )
}

export default PercentLayer;