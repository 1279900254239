import { useMemo } from "react";

import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

import EventMessageEntity from "../../../../../aqua-delivery-client-graphql/iot/entities/EventMessageEntity";
import TelemetryReportEntity, { TelemetryType, AggregationType, TelemetryReport } from "../../../../../aqua-delivery-client-graphql/iot/entities/TelemetryReportRequestEntity";
import { TelemetryVariables, generateTelemetryReportQuery } from "../../../../../aqua-delivery-client-graphql/iot/queries/useTelemetry";
import { composeQueries } from "../../../../../aqua-delivery-client-graphql/utils";

import { transformLineChartData } from "../utils/graphUtils";

const transformData = (data: Pick<TelemetryReport, 'values'> | undefined) => transformLineChartData(
    TelemetryReportEntity
        .toObject(data?.values)
        .map(event => ({ ...event, value: event.value && EventMessageEntity.convertToLiters(event.value) }))
)

type UseGetTelemetryProps = {
    registryId: string;
    unitInterval: number;
    formattedChartDataPeriod: {
        from: string;
        to: string;
    };
};

type ComposedReportResult = {
    total: TelemetryReport,
    mobile: TelemetryReport,
}

const useGetFillingTelemetry = (props: UseGetTelemetryProps) => {
    const { registryId, unitInterval, formattedChartDataPeriod } = props;

    const variables = {
        registry: registryId,
        aggregationType: AggregationType.Sum,
        interval: unitInterval,
        ...formattedChartDataPeriod,
    }

    const composedQueries = composeQueries<keyof ComposedReportResult, TelemetryVariables>(
        {
            total: { ...variables, type: TelemetryType.Volume },
            mobile: { ...variables, type: TelemetryType.Filled },
        },
        generateTelemetryReportQuery,
    );

    const query = gql`
        {
            ${composedQueries}
        }

        ${TelemetryReportEntity.fragment}
    `;

    const { data, loading: isLoading } = useQuery<ComposedReportResult>(
        query,
        {
            variables,
            fetchPolicy: 'network-only',
            pollInterval: 60 * 2000,
        }
    );

    const totalFillingsLine = useMemo(
        () => transformData(data?.total),
        [data],
    );

    const mobileAppFillingsLine = useMemo(
        () => transformData(data?.mobile),
        [data],
    );

    return {
        isLoading,
        totalFillingsLine,
        mobileAppFillingsLine,
    }
}

export default useGetFillingTelemetry;