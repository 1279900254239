import dayjs from 'dayjs';

import { NamedPeriod, Period } from '../../../../../types';

export type UsePeriodGraphPoints = {
    defaultPoints: number;
    namedPeriod: NamedPeriod | null | undefined;
    period: Period;
};

const namedPeriodIntervals: Record<NamedPeriod, (period: Period) => number> = {
    days: () => 24 * 2,
    weeks: () => (7 * 24) / 3,
    months: (period: Period) => dayjs(period.to).diff(period.from, 'days'),
};

export const getNamedPeriodInterval = (period: NamedPeriod) => {
    return namedPeriodIntervals[period];
};

const usePeriodGraphPoints = (props: UsePeriodGraphPoints) => {
    const { defaultPoints, namedPeriod, period } = props;

    return {
        points: namedPeriod ? getNamedPeriodInterval(namedPeriod)(period) : defaultPoints,
    };
};

export default usePeriodGraphPoints;
