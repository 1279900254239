import React, { FC } from 'react';

import { Typography } from 'antd';
import { When } from 'react-if';
import { TypographyProps } from 'antd/lib/typography/Typography';

import View from '../../../../../../aqua-delivery-web-client-ui/components/View';

export type SectionHeaderProps = {
    title: string,
    containerStyle?: React.CSSProperties,
    titleProps?: TypographyProps,
    hideFilters?: boolean,
}

const SectionSubHeader: FC<TypographyProps & { title: string }> = ({ title, ...props }) => {
    return (
        <Typography.Title
            level={4}
            className="section-subheading"
            {...props}
        >
            {title}
        </Typography.Title>
    );
}

const SectionHeader: React.FC<SectionHeaderProps> & { Sub: typeof SectionSubHeader } = ({ title, children, containerStyle, hideFilters, titleProps }) => {
    return (
        <View flexDirection="column" alignItems="flex-start" style={containerStyle}>
            <Typography.Title level={3} {...titleProps}>{title}</Typography.Title>
            <When condition={!hideFilters}>
                <View className="section-header">
                    {children}
                </View>
            </When>
        </View>
    )
}

SectionHeader.Sub = SectionSubHeader;

export default SectionHeader;