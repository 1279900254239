export default {
    title: 'Материалы',
    table: {
        columns: {
            material: 'Материал',
            purpose: 'Для чего он вам нужен?',
        },
        preview: 'Просмотр',
    },
};
