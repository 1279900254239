import * as React from "react";
import { Radio as RadioBase } from "antd";
import { RadioProps } from "antd/lib/radio";
import classNames from "classnames";

const Radio: React.FC<RadioProps & { size?: "large" }> & { 
    Group: typeof RadioBase.Group,
    Button: typeof RadioBase.Button 
}  = (props) => {
    return (
        <span className={classNames(`custom-radio`, props.size)}>
            <RadioBase  {...props} />
        </span>
    )
}

Radio.Group = RadioBase.Group;
Radio.Button = RadioBase.Button;

export default Radio;