import { QueryHookOptions, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import TelemetryReportEntity, { TelemetryType, AggregationType, TelemetryReport } from "../entities/TelemetryReportRequestEntity";

export type TelemetryVariables = {
    registry: string,
    from: string,
    to: string,
    interval: number,
    type: TelemetryType,
    aggregationType: AggregationType,
}

export type WaterVolumeData = { id: string, value: number, createdAt: string };

export type TelemetryResult = {
    getTelemetryReport: TelemetryReport,
}

export const generateTelemetryReportQuery = (variables: TelemetryVariables) => {
    const { registry, from, to, type, aggregationType, interval } = variables;

    return `
        getTelemetryReport(
            registry: "${registry}",
            from: "${from}",
            to: "${to}",
            type: "${type}",
            aggregationType: "${aggregationType}",
            interval: ${interval}
        ) {
            ...TelemetryReport,
        }
    `
};

export const query = gql`
    query getTelemetryReport(
        $registry: String!,
        $from: String!,
        $to: String!,
        $type: String!,
        $aggregationType: String!,
        $interval: Int!
    ) {
        getTelemetryReport(
            registry: $registry,
            from: $from,
            to: $to,
            type: $type,
            aggregationType: $aggregationType,
            interval: $interval
        ) {
            ...TelemetryReport,
        }
    }

    ${TelemetryReportEntity.fragment}
`;

export default function (options: QueryHookOptions<TelemetryResult, TelemetryVariables>) {
    return useQuery<TelemetryResult, TelemetryVariables>(query, options);
}