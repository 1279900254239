export default {
    intervals: {
        title: 'Районы и время доставки',
        labels: {
            deliveryTime: 'Время доставки',
            in: 'За',
            beforeEnd: 'минут до конца интервала',
            beforeStart: 'Окончание приема заказов',
            example: 'Например',
            title: 'Вы почти дошли до конца :)',
            subtitle: 'Осталось заполнить информацию о доставке',
        },
        messages: {
            deleted: 'Удалён',
        },
        dialogs: {
            delete: {
                title: 'Вы уверены?',
                content: 'Действие невозможно отменить',
                ok: 'Да, удалить',
                cancel: 'Нет',
            },
        },
        buttons: {
            addDelivery: 'Добавить доставку',
            delete: 'Удалить',
            addInterval: 'Добавить интервал',
        },
        helpers: {
            sameDayDelivery: {
                title: `До какого времени должен поступить заказ, чтобы вы его смогли доставить в данный период доставки.`,
                example: `Интервал доставки с 12:00 до 14:00. Заказы для
                этого интервала вы заканчиваете обрабатывать
                в 10:00 за 2 часа до начала интервала`,
            },
            expressDelivery: {
                title: `За какое время до конца интервала доставки
                должен поступить экспресс-заказ, чтобы его
                приняли?`,
                example: `Интервал доставки с 12:00 до 14:00. Экспресс-
                заказы для этого интервала вы заканчиваете
                принимать в 13:00, то есть за 60 минут до конца
                интервала`,
            },
        },
        fields: {
            districts: {
                label: 'Районы (если есть)',
            },
            payment: {
                label: 'Онлайн оплата (Выберите банк)',
            },
            days: {
                label: 'Дни доставки',
                validation: {
                    require: 'Обязательно',
                },
            },
            from: {
                label: 'Начало',
                validation: {
                    require: 'Обязательно',
                },
            },
            to: {
                label: 'Конец',
                validation: {
                    require: 'Обязательно',
                },
            },
            sameDayDelivery: {
                label: 'Доставка "день в день"',
            },
            expressDelivery: {
                label: 'Экспресс-доставка',
            },
        },
    },
};
