import React, { useContext, useMemo } from 'react';

import { Typography } from 'antd';
import { PointTooltipProps } from '@nivo/line';

import EmptyPanel from '../../../../../../aqua-delivery-web-client-ui/components/EmptyPanel';
import { formatWithSign } from '../../../../../../aqua-delivery-client-graphql/utils';
import useLocalTranslation from '../../../../../../hooks/useLocalTranslation';
import useTelemetry from '../../../../../../aqua-delivery-client-graphql/iot/queries/useTelemetry';
import TelemetryReportEntity, {
    TelemetryType,
    AggregationType,
} from '../../../../../../aqua-delivery-client-graphql/iot/entities/TelemetryReportRequestEntity';

import BaseLineGraph from '../base-graph/BaseLineGraph';
import Section from '../section/Section';
import PointTooltip from '../base-graph/PointTooltip';
import {
    formatChartDataPeriod,
    transformTimelineChartData,
    getChartDataUtcPeriod,
} from '../../utils/graphUtils';
import GraphLoader from '../base-graph/GraphLoader';
import usePeriod, { getNamedPeriodDates } from '../../hooks/usePeriod';
import { RegistryContext } from '../../Registry';
import PeriodSectionHeader from '../section/PeriodSectionHeader';
import Graph from '../base-graph/Graph';
import SectionHeader from '../section/SectionHeader';
import { NamedPeriod } from '../../../../../../types';
import usePeriodGraphPoints from '../../hooks/usePeriodGraphPoints';

type TemperatureGraphProps = {
    registryId: string;
};

const TemperatureTooltip = (props: PointTooltipProps) => {
    const { dayjs } = useContext(RegistryContext);

    return (
        <PointTooltip {...props}>
            <>
                {formatWithSign(Number(props.point.data.y)) + '°'}
                <Typography.Text style={{ fontWeight: 'normal', color: '#777', marginLeft: 10 }}>
                    {dayjs(props.point.data.x).format('DD.MM.YY HH:mm')}
                </Typography.Text>
            </>
        </PointTooltip>
    );
};

const defaultPeriod = NamedPeriod.Weeks;

const TemperatureGraph: React.FC<TemperatureGraphProps> = ({ registryId }) => {
    const { dayjs, timezone } = useContext(RegistryContext);

    const { period, utcPeriod, setPeriod, namedPeriod, isDailyPeriod } = usePeriod({
        namedPeriod: defaultPeriod,
        useNamedPeriod: true,
        ...getNamedPeriodDates(defaultPeriod, timezone),
    });

    const { points } = usePeriodGraphPoints({ defaultPoints: 60, namedPeriod, period });

    const { legend, title: graphTitle } = useLocalTranslation(
        t => t.vending.temperatureGraph,
        t => t,
    );

    const { title } = useLocalTranslation(
        t => t.vending.temperature,
        t => t,
    );

    const chartPeriodDates = getChartDataUtcPeriod(utcPeriod, timezone);
    const unitInterval = TelemetryReportEntity.getUnitInterval(chartPeriodDates, points);
    const formattedChartDataPeriod = formatChartDataPeriod(chartPeriodDates);

    const variables = {
        registry: registryId,
        type: TelemetryType.Temperature,
        aggregationType: AggregationType.Avg,
        interval: unitInterval,
        ...formattedChartDataPeriod,
    };

    const { data: temperature, loading: temperatureLoading } = useTelemetry({
        variables,
        fetchPolicy: 'network-only',
        pollInterval: 60 * 2000,
    });

    const chartData = useMemo(
        () =>
            transformTimelineChartData(
                TelemetryReportEntity.toObject(temperature?.getTelemetryReport.values).map(
                    item => ({
                        ...item,
                        value: item.value && Math.round(item.value),
                    }),
                ),
            ),
        [temperature],
    );

    const chartDataLength = chartData.aggregatedData.filter(item => item.y !== null).length;

    const minMaxValues = useMemo(() => {
        if (chartData.series.length === 0) {
            return {
                min: 0,
                max: 0,
            };
        }

        const verticalPadding = 1;

        const values = chartData.aggregatedData
            .map(item => item.y)
            .filter(item => item !== null) as number[];

        const min = Math.min(...values) - verticalPadding;
        const max = Math.max(...values) + verticalPadding;

        return { min, max };
    }, [chartData]);

    const areaBaselineValue = useMemo(() => {
        const { min, max } = minMaxValues;

        if (max < 0) return max;
        if (min > 0) return min;

        return 0;
    }, [minMaxValues]);

    return (
        <Section className="graph-section">
            <PeriodSectionHeader
                title={title}
                containerStyle={{ maxWidth: 880 }}
                period={period}
                setPeriod={setPeriod}
                timezone={timezone}
            />
            <Section>
                <SectionHeader.Sub title={graphTitle} />
                <Graph className="line-chart-body chart">
                    <GraphLoader spinning={temperatureLoading} />
                    {chartDataLength > 0 ? (
                        <BaseLineGraph
                            data={chartData.series}
                            axisLeftProps={{
                                legend,
                                format: temperature =>
                                    Number.isInteger(Number(temperature))
                                        ? formatWithSign(Number(temperature))
                                        : '',
                            }}
                            axisBottomProps={{
                                format: values =>
                                    dayjs(values).format(isDailyPeriod ? 'HH:mm' : 'DD.MM'),
                            }}
                            areaBaselineValue={areaBaselineValue}
                            tooltip={TemperatureTooltip}
                            {...minMaxValues}
                        />
                    ) : (
                        <EmptyPanel />
                    )}
                </Graph>
            </Section>
        </Section>
    );
};

export default TemperatureGraph;
