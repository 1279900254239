import React, { useCallback } from 'react';

import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Spin, ConfigProvider, Typography } from 'antd';
import { When } from 'react-if';
import dayjs, { Dayjs } from 'dayjs';

import withNetworkLayer from '../../../../aqua-delivery-web-client-ui/components/network/withNetworkLayer';
import RegistryEntity from '../../../../aqua-delivery-client-graphql/iot/entities/RegistryEntity';
import useAntdLocale from '../../../../hooks/useAntdLocale';
import Title from '../../../../aqua-delivery-web-client-ui/components/page/Title';
import { TPostalAddress } from '../../../../aqua-delivery-client-graphql/iot/entities/PostalAddressEntity';
import useGetRegistry from '../../../../aqua-delivery-client-graphql/iot/queries/useGetRegistry';
import { getTimezoneFromCoordinates } from '../../../../aqua-delivery-web-client-ui/Utils';
import { createDayjsWithTimezone } from '../../../../aqua-delivery-web-client-ui/i18n';
import { formatWithSign } from '../../../../aqua-delivery-client-graphql/utils';
import useLocalTranslation from '../../../../hooks/useLocalTranslation';
import Help from '../../../../aqua-delivery-web-client-ui/components/Help';

import TemperatureGraph from './components/graphs/TemperatureGraph';
import VolumeGraph from './components/graphs/VolumeGraph';
import FillingsGraph from './components/graphs/FillingsGraph/FillingsGraph';

import './styles/styles.less';

type RouteParams = {
    id: string;
};

type RegistryProps = RouteComponentProps<RouteParams>;

const buildPageTitle = (postalAddress: TPostalAddress) => {
    return postalAddress.streetAddress
        ? `${postalAddress.streetAddress} (${postalAddress.addressLocality})`
        : postalAddress.addressLocality;
};

export const RegistryContext = React.createContext<{
    timezone: number;
    dayjs: (date: Dayjs | Date | string | number) => Dayjs;
}>({
    timezone: 0,
    dayjs: date => createDayjsWithTimezone(date, 0),
});

const Registry: React.FC<RegistryProps> = props => {
    const [timezoneTitle, timezoneHelper] = useLocalTranslation(
        t => t.vending.registry,
        t => [t.timezoneTitle, t.timezoneHelper],
    );

    const history = useHistory();

    const goBack = useCallback(async () => history.push('/vending'), [history]);

    const { antdLocale } = useAntdLocale();

    const registryId = RegistryEntity.createId(props.match.params.id);

    const { data: registryData, loading } = useGetRegistry({
        variables: { id: registryId },
    });

    const postalAddress = registryData?.registry.postalAddress;

    const pageTitle = postalAddress && buildPageTitle(postalAddress);

    const coordinates = registryData?.registry.postalAddress.coordinates;

    const timezone = coordinates
        ? getTimezoneFromCoordinates(coordinates.latitude)
        : dayjs().utcOffset();

    return (
        <RegistryContext.Provider
            value={{
                timezone,
                dayjs: date => createDayjsWithTimezone(date, timezone),
            }}
        >
            <Spin spinning={loading}>
                <When condition={!!registryData}>
                    <ConfigProvider locale={antdLocale}>
                        <Title
                            title={pageTitle}
                            titleStyle={{ margin: 0, marginLeft: 16, lineHeight: 1 }}
                            onBackButtonClick={goBack}
                        />

                        <Typography.Text style={{ marginTop: 6 }}>
                            {timezoneTitle}:{' '}
                            <Typography.Text strong>UTC{formatWithSign(timezone)}</Typography.Text>
                        </Typography.Text>
                        <Help content={timezoneHelper} />

                        <VolumeGraph registryId={registryId} />
                        <TemperatureGraph registryId={registryId} />
                        <FillingsGraph registryId={registryId} />
                    </ConfigProvider>
                </When>
            </Spin>
        </RegistryContext.Provider>
    );
};

export default withNetworkLayer({ service: 'iot' })(Registry);
