export default {
    title: 'Cabinet of partner',
    labels: {
        tryOrderCabinet: 'Try demo version for operators',
        tryMobileApp: 'and mobile app demo version',
        loading: 'Wait for the download to finish',
    },
    buttons: {
        skip: 'Skip',
        next: 'Next',
        done: 'Complete',
    },
    steps: {
        password: {
            title: 'Set up a password to use the system',
            fields: {
                password: {
                    label: 'Password',
                    placeholder: 'Try to keep the password non-trivial*',
                    help: '* use letters, numbers, and punctuation marks (preferably)',
                    validators: {
                        require: 'Required',
                        equal: 'Passwords must match',
                    },
                },
                repeatPassword: {
                    label: 'Repeat password',
                    validators: {
                        require: 'Required',
                        equal: 'Passwords must match',
                    },
                },
            },
        },
        company: {
            title: 'We need more information about your company',
            fields: {
                partnerName: {
                    label: 'Company Name',
                    placeholder: 'Company LLC',
                    validators: {
                        require: 'Required',
                    },
                },
                location: {
                    label: 'City',
                    validators: {
                        require: 'Required',
                    },
                },
                itn: {
                    label: 'INN',
                    placeholder: '1234567890',
                    validators: {
                        require: 'Required',
                    },
                },
                currency: {
                    label: 'Currency',
                    validators: {
                        require: 'Required',
                    },
                },
                country: {
                    label: 'Country',
                    validators: {
                        require: 'Required',
                    },
                },
                contractEtching: {
                    labelStart: 'I confirm that I have read the',
                    labelLink: ' contract-offer ',
                    labelFinish: 'and accept its terms',
                    validators: {
                        require: 'Required',
                    },
                },
            },
        },
    },
};
