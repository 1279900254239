import registration from './registration';
import preregistration from './preregistration';
import login from './login';
import layout from './layout';
import dashboard from './dashboard';
import staticPages from './staticPages';
import transactions from './transactions';
import partnerSettings from './partnerSettings';
import finishRegistration from './finishRegistration';
import recoveryPassword from './recoveryPassword';
import profile from './profile';
import vending from './vending';
import couriers from './couriers';
import materials from './materials';
import banners from './banners';
import smsReport from './smsReport';

export default {
    registration,
    preregistration,
    login,
    layout,
    dashboard,
    staticPages,
    transactions,
    partnerSettings,
    finishRegistration,
    recoveryPassword,
    profile,
    vending,
    couriers,
    materials,
    banners,
    smsReport,
};
