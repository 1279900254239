import { StepName } from "../../routes/partner-cabinet/routes/finish-registration/components/stepperHelper/StepNameEnum";

export default {
    buttons: {
        next: "Далее",
        complete: "Завершить"
    },
    pricingPlans: {
        title: "Спасибо",
        title2: "за регистрацию!",
        subtitle: "Выберите тарифный план",
        pricesTable: {
            title: 'Тарифный план — ',
        },
        messages: {
            notSelectedPricingPlan: "Выберите тарифный план"
        },
        labels: {
            pricingPlanDuration: 'Продолжительность',
            pricingPlanMonthlyPrice: 'Цена в месяц',
            pricingPlanPrice: 'Сумма',
            launchAmount: "Стартовый платёж",
            pricingPlanActions: "Выбрать",
        }
    },
    payment: {
        title: "Как будете оплачивать?",
        card: {
            bankCard: {
                title: "Онлайн-оплата",
                // title: "Оплатить картой",
                content: "Заплатите онлайн",
            },
            contract: {
                title: "Реквизиты",
                content: "Договор",
            }
        },
        labels: {
            oferta: "Договор_оферты",
            paymentMethodsNotAvailable: "К сожалению, вам недоступен ни один из существующих способов оплаты.",
            callUs: "Свяжитесь с нами по телефону:",
        },
        fields: {
            file: {
                label: "Загрузите реквизиты",
                validators: {
                    require: "Поле обязательно для заполнения"
                }
            }
        },
        buttons: {
            download: "Скачать",
            back: "Назад на выбор тарифа"
        },
        tooltips: {
            payWithOnlinePayment: 'Выберите один из доступных способов оплаты',
        },
    },
    company: {
        title: "Расскажите",
        title2: "о вашей компании",
        subtitle: `Нам нужно еще немного данных
        о вас для индивидуального приложения 
        и сайта продаж`,
        buttons: {
            save: "Сохранить",
            next: "Далее",
            skip: "Пропустить",
            back: "Назад"
        },
        navigation: {
            about: "О компании",
            contacts: "Контакты компании",
            dispatchers: "Диспетчера",
            links: "Ссылки",
            paymmentMethods: "Как будут платить клиенты?",
            details: "Реквизиты компании",
            assets: "Фирменный дизайн"
        },
        steps: {
            main: {
                title: "Информация о компании",
                fields: {
                    brandName: {
                        label: "Название бренда",
                        validators: {
                            require: "Вы пропустили поле"
                        }
                    },
                    memo: {
                        label: "Этот текст будет на сайте продаж и в клиентском приложении",
                        placeholder: "Расскажите, с какого года вы на рынке, в чём ваши преимущества, какой у вас ассортимент",
                        validators: {
                            require: "Вы пропустили поле"
                        }
                    }
                }
            },
            contacts: {
                title: "Контакты компании",
                fields: {
                    directorPhoneNumber: {
                        label: "Телефон руководителя",
                        validators: {
                            require: "Вы пропустили поле"
                        }
                    },
                    directorEmail: {
                        label: "E-mail руководителя",
                        validators: {
                            require: "Вы пропустили поле",
                            email: "Похоже вы указали невалидный E-mail"
                        }
                    },
                    companyPhoneNumber: {
                        label: "Телефон компании",
                        validators: {
                            require: "Вы пропустили поле"
                        }
                    },
                    companyEmail: {
                        label: "E-mail компании",
                        validators: {
                            require: "Вы пропустили поле",
                            email: "Похоже вы указали невалидный E-mail"
                        }
                    },
                    pickupLocation: {
                        label: "Адрес самовывоза",
                    }
                }
            },
            dispatchers: {
                title: "Информация о диспетчере",
                fields: {
                    dispatcherPhoneNumber: {
                        label: "Телефон диспетчера",
                        validators: {
                            require: "Вы пропустили поле"
                        }
                    },
                    dispatcherEmail: {
                        label: "E-mail диспетчера",
                        validators: {
                            require: "Вы пропустили поле",
                            email: "Похоже вы указали невалидный E-mail"
                        }
                    },
                }
            },
            links: {
                title: "Ссылки на социальные сети",
                fields: {
                    site: { label: "Сайт", check: "У нас нет сайта" },
                    vkLink: { label: "Группа Вконтакте", check: "У нас нет группы" },
                    instagramLink: { label: "Инстаграм", check: "У нас нет Инстаграма" },
                    misc: { label: "Другие источники" },
                }
            },
            paymentMethods: {
                title: "Как будут платить клиенты?",
                fields: {
                    onlinePayment: {
                        notSelected: "Не выбрано",
                    },
                    other: {
                        label: "Другой вариант"
                    } 
                }
            },
            assets: {
                title: "Загрузите логотип и брендбук (если есть)",
                labels: {
                    sendEmail: "Или отправьте данные на почту",
                    sendEmail2: "В теме письма напишите с какого вы города и название вашей компании/бренда"
                }
            },
            details: {
                title: "Загрузите реквизиты вашей компании",
                fields: {
                    companyDetails: {
                        validators: {
                            require: 'Реквизиты компании обязательны для загрузки'
                        }
                    }
                }
            },
        }
    },
    partnerFinish: {
        title: 'Спасибо за регистрацию!',
        discription:
            'В ближайшее время мы с вами свяжемся, зададим несколько уточняющих вопросов и начнем собирать Ваше приложение.'
    },
    stepper: {
        [StepName.pricingPlan]: "Выбор тарифа",
        [StepName.payment]: "Оплата",
        [StepName.companyInfo]: "О компании",
    }
}