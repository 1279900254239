import { AuthMethod } from "../../routes/login/types";

export default {
    title: 'Авторизация',
    form: {
        fields: {
            username: {
                placeholder: "example@mail.com",
                label: "E-mail",
                validations: {
                    require: "Поле обязательно для заполнения",
                    email: "Вы указали невалидный E-mail"
                }
            },
            password: {
                label: "Пароль",
                validations: {
                    require: "Поле обязательно для заполнения",
                }
            },
            rememberMe: {
                label: "Запомнить меня",
            },
            phoneNumber: {
                label: "Номер телефона",
                validations: {
                    phone: "Вы указали невалидный номер телефона",
                    require: "Поле обязательно для заполнения",
                }
            }
        },
        submitButton: "Войти",
        authMethods: {
            [AuthMethod.Phone]: "По номеру телефона",
            [AuthMethod.Email]: "По E-mail"
        }
    },
    links: {
        registration: 'Регистрация',
        recoveryPassword: 'Я забыл пароль :('
    }
}