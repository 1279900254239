import { gql } from "apollo-boost";

import { BaseEntity } from "../../types";
import { Dayjs } from "dayjs";

const MAX_TELEMETRY_POINTS = 300;

export enum TelemetryType {
    Volume = 'TELEMETRY_VOLUME',
    Pressure = 'TELEMETRY_PRESSURE',
    Temperature = 'TELEMETRY_TEMPERATURE',
    Height = 'TELEMETRY_HEIGHT',
    Filled = 'FILLED',
}

export enum AggregationType {
    Avg = 'AVG',
    Sum = 'SUM',
}

export type TelemetryReportValue = [string, number | null];

export declare type TelemetryReport = BaseEntity<{
    values: TelemetryReportValue[],
}>

const fragment = gql`
    fragment TelemetryReport on TelemetryReport {
        id,
        values,
    }
`;

const TelemetryReportEntity = {
    fragment,
    getUnitInterval: (period: { from: Dayjs, to: Dayjs }, points: number) => {
        if (points > MAX_TELEMETRY_POINTS) throw new Error(`Max number of telemetry points is ${MAX_TELEMETRY_POINTS}`);

        const dateDiff = period.to.diff(period.from, 'second');

        return Math.ceil(dateDiff / points);
    },
    toObject: (values: TelemetryReportValue[] | undefined) => {
        return values
            ? values.map(([createdAt, value]) => ({ createdAt, value }))
            : [];
    },
};

export default TelemetryReportEntity;