export default {
    title: 'Couriers',
    addCourier: 'Courier',
    table: {
        columns: {
            name: 'Name',
            phoneNumber: 'Phone number',
            email: 'E-mail',
        },
        tooltips: {
            notVerified: 'Courier has not yet been confirmed by the system',
            deleteCourier: 'Delete courier',
        },
    },
    form: {
        title: 'New courier',
        fields: {
            firstName: {
                label: 'First name',
                placeholder: 'John',
            },
            middleName: {
                label: 'Last name',
                placeholder: 'Smith',
            },
            familyName: {
                label: 'Middle name (optional)',
                placeholder: '',
            },
            email: {
                label: 'E-mail',
                placeholder: 'example@gmail.com',
            },
            phoneNumber: {
                label: 'Phone number',
            },
            password: {
                label: 'Password',
            },
        },
        require: 'This field is required',
        submitButton: 'Add',
        messages: {
            success: 'Courier created',
        },
    },
    deleteButton: {
        title: 'Are you sure you want to delete the courier {{firstName}} {{middleName}}?',
        titleNoMiddleName: 'Are you sure you want to delete the courier {{firstName}}?',
        content: 'This action cannot be undone.',
        ok: 'Yes, delete',
        cancel: 'No',
        messages: {
            success: 'The courier has been deleted',
        },
    },
};
