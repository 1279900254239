import * as React from "react";

export type SVGProps = Partial<{
    width: number | string,
    height: number | string,
    stroke: string,
    strokeWidth: number
    rotate: number,
    fill: string,
    style: any,
    defaultStroke?: boolean
}> & { source: any }

const SVG: React.FC<SVGProps> = ({ source, rotate, defaultStroke, ...props }) => {
    const SourceComponent = source
    const stroke = defaultStroke? {} : {stroke:"#4671ff" }
    return <SourceComponent
        {...stroke}
        {...props}
        style={{
            transform: `rotate(${rotate || 0}deg)`,
            ...props.style
        }}
    />
}

export default SVG