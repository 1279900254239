import { Theme } from '@nivo/core';

const nivoTheme: Theme = {
    fontSize: 12,
    grid: {
        line: {
            stroke: '#D9D9D9',
            strokeWidth: 1,
        },
    },
    axis: {
        ticks: {
            text: {
                color: '#404040',
                fontSize: 12,
                opacity: 0.67,
            },
            line: {
                stroke: '#E6E6E6',
                strokeWidth: 2,
            },
        },
    },
};

export default nivoTheme;
