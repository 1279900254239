import { useState } from 'react';

import dayjs from 'dayjs';

import { Period, NamedPeriod } from '../../../../../types';

export type UsePeriod = DatePeriodState;

export type DatePeriodState =
    | ({
          namedPeriod: null;
          useNamedPeriod: false;
      } & Period)
    | ({
          namedPeriod: NamedPeriod;
          useNamedPeriod: true;
      } & Period);

export const getNamedPeriodDates = (period: NamedPeriod, timezone?: number) => {
    const currDate = dayjs()
        .set('seconds', 0)
        .utcOffset(timezone !== undefined ? timezone : dayjs().utcOffset());

    const to = currDate;
    const from = currDate.clone().subtract(1, period);

    return { from, to };
};

const usePeriod = (initialPeriod: UsePeriod) => {
    const [period, setPeriod] = useState<DatePeriodState>(initialPeriod);

    const periodDiff = period.to.diff(period.from, 'seconds');

    return {
        period,
        namedPeriod: period.namedPeriod,
        utcPeriod: {
            ...period,
            from: period.from.clone().utc(),
            to: period.to.clone().utc(),
        },
        setPeriod,
        isDailyPeriod: periodDiff <= 60 * 60 * 24,
    };
};

export default usePeriod;
