import { gql } from "apollo-boost";

import { BaseEntity } from "../../types";

export declare type TRegistry = BaseEntity<{
    username: string;
    active: boolean;
    empty: boolean | null;
    enabled: boolean;
}>

const fragment = gql`
    fragment Registry on Registry {
        id,
        username,
        active,
        empty,
        enabled
    }
`;

const RegistryEntity = {
    fragment,
    createId: (id: string) => `/iot/registries/${id}`,
};

export default RegistryEntity