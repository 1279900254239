export default {
    blocks: {
        marketerCabinet: {
            title: 'Кабинет маркетолога',
            body: 'Посмотреть отчёты и проанализировать ситуацию',
        },
        orderCabinet: {
            title: 'Кабинет заказов',
            body: 'Посмотреть заказы и клиентов, добавлять и редактировать',
        },
        settingsCabinet: {
            title: 'Кабинет настроек',
            body: 'Внести изменения к товарам, услугам, изображениям, описаниям и ценам',
        },
        clientApp: {
            title: 'Клиентское приложение',
            //body: "Внести изменения к товарам, услугам, изображениям, описаниям и ценамь"
        },
        orderSite: {
            title: 'Сайт для заказов',
            //body: "Внести изменения к товарам, услугам, изображениям, описаниям и ценамь"
        },
        knowledge: {
            title: 'База знаний',
            body: 'Найти ответы на вопросы по работе с системой',
        },
    },
    welcome: {
        title: 'Спасибо',
        title2: 'за регистрацию!',
        subTitle: 'Мы вам скоро позвоним сами :)',
        phone: 'Либо можете связаться с нами по телефону',
        subPhone: 'для дальнейших действий',
    },
    labels: {
        login: 'Логин',
        password: 'Пароль',
        links: {
            apple: 'App Store',
            google: 'Google Play',
            smartLink: 'Умная ссылка',
        },
    },
    tooltips: {
        copy: 'Скопировать',
        showPassword: 'Показать пароль',
        hidePassword: 'Скрыть пароль',
        smartLink:
            'Адрес специальной страницы, с которой будет происходить автоматический редирект в стор приложений',
    },
    messages: {
        login: 'Логин скопирован',
        password: 'Пароль скопирован',
        linkCopied: 'Ссылка скопирована',
    },
    buttons: {
        goToCabinet: 'Перейти',
    },
    documents: {
        title: 'Документы',
        content: {
            table: {
                label: 'Счёт за',
                date: 'Дата',
                total: 'Сумма',
                panel: {
                    header: 'Другое',
                    extra: 'Акты',
                    content: 'Акт',
                },
                error: 'Счета не найдены',
            },
        },
        state: {
            invoice_created: 'Оплатить',
            invoice_paid: 'Оплачен',
            invoice_archived: 'В архиве',
        },
    },
};
