import React from 'react';

import { PointTooltipProps } from '@nivo/line';

import View from '../../../../../../aqua-delivery-web-client-ui/components/View';

const PointTooltip: React.FC<PointTooltipProps & { children: string | React.ReactElement }> = ({ children, ...other }) => (
    <View className={'graph-tooltip'}>
        {children}
    </View>
);

export default PointTooltip;