export default {
    intervals: {
        title: "Delivery areas and schedule",
        labels: {
            deliveryTime: "Delivery Time",
            in: 'For',
            beforeEnd: "minute until the end of the interval",
            beforeStart: "End of order acceptance periods",
            example: "For Example",
            title: "You've almost there :)",
            subtitle: "Just fill out delivery information"
        },
        messages: {
            deleted: "Deleted"
        },
        dialogs: {
            delete: {
                title: "Are you sure?",
                content: 'The action cannot be undone',
                ok: "Yes, delete",
                cancel: "No"
            }
        },
        buttons: {
            addDelivery: "Add delivery",
            delete: "Delete",
            addInterval: "Add interval"
        },
        helpers: {
            sameDayDelivery: {
                title: `Until when should the order arrive so that you can deliver it during this delivery period.`,
                example: `Delivery Interval from 12:00 to 14: 00. Orders for 
                this interval you finish processing
    at 10:00 2 hour before the start of the interval`
            },
            expressDelivery: {
                title: `How long before the end of the delivery interval
    an Express order must be received in order for it
    accepted?`,
                example: `Delivery Interval from 12:00 to 14: 00. Express-
    orders for this interval you finish
    take at 13:00, that is, 60 minute before the end
    interval's`
            }
        },
        fields: {
            districts: {
                label: "Districts (if any)"
            },
            payment: {
                label: "Online payment (Choice bank)"
            },
            days: {
                label: "Day of delivery",
                validation: {
                    require: "Required"
                }
            },
            from: {
                label: "From",
                validation: {
                    require: "Required"
                }
            },
            to: {
                label: "To",
                validation: {
                    require: "Required"
                }
            },
            sameDayDelivery: {
                label: 'Intraday delivery',
            },
            expressDelivery: {
                label: 'Express delivery'
            },
        }
    }

}