import { gql } from "apollo-boost";

import { BaseEntity } from "../../types";
import { roundNumber } from "../../utils";

export enum EventMessage {
    AbstractEventMessage = 'AbstractEventMessage',
    TemperatureEventMessage = 'TemperatureEventMessage',
    PressureEventMessage = 'PressureEventMessage',
    VolumeEventMessage = 'VolumeEventMessage',
    FillProgressEventMessage = 'FillProgressEventMessage',
}

export declare type TEventMessage = BaseEntity<{
    value: number,
    createdAt: string,
}>

const fragment = (eventType: EventMessage) => gql`
    fragment ${eventType} on ${eventType} {
        id,
        value,
        createdAt,
    }
`;

const EventMessageEntity = {
    fragment,
    convertToLiters: (milliliters: number) => roundNumber(milliliters / 1000, 3),
};

export default EventMessageEntity;