import React from 'react';

import classNames from 'classnames';

import View, { ViewProps } from '../../../../../../aqua-delivery-web-client-ui/components/View';

type GraphProps = {
    containerStyle?: React.CSSProperties,
} & ViewProps;

const Graph: React.FC<GraphProps> = ({ containerStyle, children, className, ...other }) => {
    return (
        <View flexDirection="column" className={classNames('graph-container', className)} style={containerStyle} {...other}>
            {children}
        </View>
    )
}

export default Graph;