export default {
    title: 'Password recovery',
    form: {
        fields: {
            phone: {
                label: 'Phone',
                validations: {
                    require: 'this field is required',
                },
            },
            verificationCode: {
                label: 'Last {{codeLength}} numbers',
                validations: {
                    require: 'this field is required',
                },
            },
            password: {
                label: 'New password',
                validations: {
                    require: 'this field is required',
                    repeat: 'Passwords must match',
                },
            },
            repeatPassword: {
                label: 'Repeat the new password',
                validations: {
                    require: 'this field is required',
                    repeat: 'Passwords must match',
                },
            },
        },
        submitButton: 'Войти',
    },
    labels: {
        phoneStep:
            'Enter your phone number, it will receive an incoming call, you do not need to answer',
        verificationStep:
            'You will receive an incoming call to {{phoneNumber}}, enter the last {{codeLength}} numbers',
        createPasswordStep: 'Great :) Now enter your new password',
    },
    buttons: {
        sendCode: 'Send',
        createPassword: 'Change password',
        repeatVerification: "I didn't get the code :(",
    },
    messages: {
        successResetPassword: 'Password changed successfully',
        requestResetPassword: `Code re-sent to {{phoneNumber}}`,
    },
};
