import React, { useContext, useMemo } from 'react';

import { DatumValue } from '@nivo/core';
import { Layer } from '@nivo/line';
import { LegendProps } from '@nivo/legends';

import EmptyPanel from '../../../../../../../aqua-delivery-web-client-ui/components/EmptyPanel';
import useLocalTranslation from '../../../../../../../hooks/useLocalTranslation';
import TelemetryReportEntity from '../../../../../../../aqua-delivery-client-graphql/iot/entities/TelemetryReportRequestEntity';
import useWindowSize from '../../../../../../../aqua-delivery-web-client-ui/hooks/useWindowSize';
import BaseLineGraph from '../../base-graph/BaseLineGraph';
import Section from '../../section/Section';
import { formatChartDataPeriod, getChartDataUtcPeriod } from '../../../utils/graphUtils';
import GraphLoader from '../../base-graph/GraphLoader';
import usePeriod, { getNamedPeriodDates } from '../../../hooks/usePeriod';
import useGetFillingTelemetry from '../../../queries/useGetFillingTelemetry';
import { RegistryContext } from '../../../Registry';
import PeriodSectionHeader from '../../section/PeriodSectionHeader';
import Graph from '../../base-graph/Graph';
import SectionBody from '../../section/SectionBody';
import SectionHeader from '../../section/SectionHeader';
import FillingsGraphTooltip from './FillingsGraphTooltip';
import BarLayer from './layers/BarLayer';
import { NamedPeriod } from '../../../../../../../types';
import usePeriodGraphPoints from '../../../hooks/usePeriodGraphPoints';
import Indicators from './Indicators';

type FillingsGraphProps = {
    registryId: string;
};

export enum FillingGraphLegends {
    MobileAppVolume = 'mobileAppVolume',
    TotalVolume = 'totalVolume',
}

export const MAX_FILLINGS_IN_DAY = 60;

const defaultPeriod = NamedPeriod.Days;

const graphLayers: Layer[] = [
    'grid',
    'axes',
    'mesh',
    BarLayer,
    'crosshair',
    'markers',
    'legends',
    'slices',
];

const colors = ['#1CBF2F', '#4671FF'];

const FillingsGraph: React.FC<FillingsGraphProps> = ({ registryId }) => {
    const { dayjs, timezone } = useContext(RegistryContext);

    const { period, utcPeriod, setPeriod, namedPeriod, isDailyPeriod } = usePeriod({
        namedPeriod: defaultPeriod,
        useNamedPeriod: true,
        ...getNamedPeriodDates(defaultPeriod, timezone),
    });

    const { points } = usePeriodGraphPoints({
        defaultPoints: MAX_FILLINGS_IN_DAY,
        namedPeriod,
        period,
    });

    const [legend, graphTitle] = useLocalTranslation(
        t => t.vending.fillingsGraph,
        t => [t.legend, t.title],
    );

    const [title] = useLocalTranslation(
        t => t.vending,
        t => [t.fillings.title],
    );

    const legends = useLocalTranslation(
        t => t.vending.fillingsGraph.legends,
        t => t,
    );

    const { isMobile } = useWindowSize();

    const graphMargins = useMemo(
        () => ({
            top: 20,
            right: 10,
            bottom: isMobile ? 80 : 60,
            left: 70,
        }),
        [isMobile],
    );

    const axisLeftProps = useMemo(
        () => ({
            legend,
            format: (waterVolume: DatumValue) =>
                Number.isInteger(Number(waterVolume)) ? Number(waterVolume) : '',
        }),
        [legend],
    );

    const chartPeriodDates = getChartDataUtcPeriod(utcPeriod, timezone);
    const unitInterval = TelemetryReportEntity.getUnitInterval(chartPeriodDates, points);
    const formattedChartDataPeriod = formatChartDataPeriod(chartPeriodDates);

    const { isLoading, totalFillingsLine, mobileAppFillingsLine } = useGetFillingTelemetry({
        registryId,
        unitInterval,
        formattedChartDataPeriod,
    });

    const chartData = useMemo(() => {
        return [
            { id: legends[FillingGraphLegends.MobileAppVolume], data: mobileAppFillingsLine },
            { id: legends[FillingGraphLegends.TotalVolume], data: totalFillingsLine },
        ];
    }, [totalFillingsLine, mobileAppFillingsLine, legends]);

    const totalVolume = useMemo(
        () => totalFillingsLine.reduce((sum, item) => sum + (item.y || 0), 0),
        [totalFillingsLine],
    );
    const mobileVolume = useMemo(
        () => mobileAppFillingsLine.reduce((sum, item) => sum + (item.y || 0), 0),
        [mobileAppFillingsLine],
    );

    const chartDataLength = Math.max(
        totalFillingsLine.filter(item => item.y !== null).length,
        mobileAppFillingsLine.filter(item => item.y !== null).length,
    );

    const minMaxValues = useMemo(() => {
        if (totalFillingsLine.length === 0) return {};

        const values = totalFillingsLine
            .map(item => item.y)
            .filter(item => item !== null) as number[];

        const min = 0;
        const max = Math.max(...values) + 0.5;

        return { min, max };
    }, [totalFillingsLine]);

    const graphLegends: LegendProps[] = useMemo(
        () => [
            {
                anchor: 'bottom-left',
                direction: 'row',
                itemHeight: 20,
                itemWidth: 120,
                translateY: isMobile ? 70 : 50,
                symbolSize: 12,
                symbolShape: 'circle',
            },
        ],
        [isMobile],
    );

    const axisBottomProps = useMemo(
        () => ({
            format: (value: DatumValue) => dayjs(value).format(isDailyPeriod ? 'HH:mm' : 'DD.MM'),
        }),
        [dayjs, isDailyPeriod],
    );

    return (
        <Section className="graph-section">
            <PeriodSectionHeader
                title={title}
                containerStyle={{ maxWidth: 880 }}
                period={period}
                setPeriod={setPeriod}
                timezone={timezone}
            />
            <SectionBody>
                <Section>
                    <SectionHeader.Sub title={graphTitle} />
                    <Graph className="bar-chart-body chart">
                        {chartDataLength ? (
                            <>
                                <GraphLoader spinning={isLoading} />
                                <BaseLineGraph
                                    data={chartData}
                                    margin={graphMargins}
                                    axisLeftProps={axisLeftProps}
                                    axisBottomProps={axisBottomProps}
                                    sliceTooltip={FillingsGraphTooltip}
                                    enableCrosshair
                                    crosshairType="left"
                                    layers={graphLayers}
                                    legends={graphLegends}
                                    enableSlices="x"
                                    colors={colors}
                                    {...minMaxValues}
                                />
                            </>
                        ) : (
                            <EmptyPanel />
                        )}
                    </Graph>
                </Section>

                <Indicators totalVolume={totalVolume} mobileVolume={mobileVolume} />
            </SectionBody>
        </Section>
    );
};

export default FillingsGraph;
