import React, { FC } from 'react';

import classNames from 'classnames';

import View from '../../../../../../aqua-delivery-web-client-ui/components/View';

type SectionProps = {
    containerStyle?: React.CSSProperties;
    className?: string;
}

const Section: React.FC<SectionProps> = ({ containerStyle, className, children }) => {
    return (
        <View flexDirection="column" className={classNames('section-container', className)} style={containerStyle}>
            {children}
        </View>
    )
}

export default Section;