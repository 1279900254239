import { useRef, useEffect, useCallback } from 'react';

import throttle from 'lodash.throttle';

const useThrottle = <T>(callback: (arg?: T) => void, delay: number) => {
    const cbRef = useRef(callback);

    useEffect(() => {
        cbRef.current = callback;
    });

    return useCallback(
        throttle((arg?: T) => cbRef.current(arg), delay),
        [delay],
    );
};

export default useThrottle;
