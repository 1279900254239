export default {
    nav: {
        dashboard: 'Рабочий стол',
        balance: 'Баланс',
        reports: 'Отчеты',
        documents: 'Документы',
        vending: 'Киоски',
        couriers: 'Курьеры',
        materials: 'Материалы',
        banners: 'Баннеры',
        smsReport: 'SMS-отчет',
    },
    header: {
        company: 'Компания',
        user: 'Пользователь',
    },
    mobileMenu: {
        company: 'Настройки компании',
        user: 'Мой профиль',
    },
    footer: {
        contractOffer: 'Договор оферты',
    },
    buttons: {
        logout: 'Выйти',
    },
};
