import React, { useMemo, useContext } from 'react';

import { PointTooltipProps } from '@nivo/line';
import { Typography } from 'antd';

import EmptyPanel from '../../../../../../aqua-delivery-web-client-ui/components/EmptyPanel';
import useLocalTranslation from '../../../../../../hooks/useLocalTranslation';
import useTelemetry from '../../../../../../aqua-delivery-client-graphql/iot/queries/useTelemetry';
import TelemetryReportEntity, {
    TelemetryType,
    AggregationType,
} from '../../../../../../aqua-delivery-client-graphql/iot/entities/TelemetryReportRequestEntity';
import { roundNumber } from '../../../../../../aqua-delivery-client-graphql/utils';

import BaseLineGraph from '../base-graph/BaseLineGraph';
import Section from '../section/Section';
import PointTooltip from '../base-graph/PointTooltip';
import GraphLoader from '../base-graph/GraphLoader';
import {
    formatChartDataPeriod,
    transformTimelineChartData,
    getChartDataUtcPeriod,
} from '../../utils/graphUtils';
import usePeriod, { getNamedPeriodDates } from '../../hooks/usePeriod';
import { RegistryContext } from '../../Registry';
import Graph from '../base-graph/Graph';
import PeriodSectionHeader from '../section/PeriodSectionHeader';
import SectionHeader from '../section/SectionHeader';
import { NamedPeriod } from '../../../../../../types';
import usePeriodGraphPoints from '../../hooks/usePeriodGraphPoints';
import { DatumValue } from '@nivo/core';

type VolumeGraphProps = {
    registryId: string;
};

const WaterVolumeTooltip = (props: PointTooltipProps) => {
    const { dayjs } = useContext(RegistryContext);

    const [meters] = useLocalTranslation(
        t => t.vending,
        t => [t.meters],
    );

    return (
        <PointTooltip {...props}>
            <>
                {`${props.point.data.y} ${meters}`}
                <Typography.Text style={{ fontWeight: 'normal', color: '#777', marginLeft: 10 }}>
                    {dayjs(props.point.data.x).format('DD.MM.YY HH:mm')}
                </Typography.Text>
            </>
        </PointTooltip>
    );
};

const defaultPeriod = NamedPeriod.Weeks;

const VolumeGraph: React.FC<VolumeGraphProps> = ({ registryId }) => {
    const { dayjs, timezone } = useContext(RegistryContext);

    const { period, utcPeriod, setPeriod, namedPeriod, isDailyPeriod } = usePeriod({
        namedPeriod: defaultPeriod,
        useNamedPeriod: true,
        ...getNamedPeriodDates(defaultPeriod, timezone),
    });

    const { points } = usePeriodGraphPoints({ defaultPoints: 60, namedPeriod, period });

    const { legend, title: graphTitle } = useLocalTranslation(
        t => t.vending.waterVolumeGraph,
        t => t,
    );

    const { title } = useLocalTranslation(
        t => t.vending.waterVolume,
        t => t,
    );

    const chartPeriodDates = getChartDataUtcPeriod(utcPeriod, timezone);
    const unitInterval = TelemetryReportEntity.getUnitInterval(chartPeriodDates, points);
    const formattedChartDataPeriod = formatChartDataPeriod(chartPeriodDates);

    const variables = {
        registry: registryId,
        type: TelemetryType.Height,
        aggregationType: AggregationType.Avg,
        interval: unitInterval,
        ...formattedChartDataPeriod,
    };

    const { data: waterVolume, loading: waterVolumeLoading } = useTelemetry({
        variables,
        fetchPolicy: 'network-only',
        pollInterval: 60 * 2000,
    });

    const chartData = useMemo(
        () =>
            transformTimelineChartData(
                TelemetryReportEntity.toObject(
                    waterVolume?.getTelemetryReport.values,
                ).map(event => ({ ...event, value: event.value && roundNumber(event.value, 2) })),
            ),
        [waterVolume],
    );

    const chartDataLength = chartData.aggregatedData.filter(item => item.y !== null).length;

    const minMaxValues = useMemo(() => {
        if (chartData.series.length === 0) return {};

        const values = chartData.aggregatedData
            .map(item => item.y)
            .filter(item => item !== null) as number[];

        const min = 0;
        const max = Math.max(...values) + 1;

        return { min, max };
    }, [chartData]);

    const axisLeftProps = useMemo(
        () => ({
            legend,
            format: (waterVolume: DatumValue) =>
                Number.isInteger(Number(waterVolume)) ? Number(waterVolume) : '',
        }),
        [legend],
    );

    const axisBottomProps = useMemo(
        () => ({
            format: (values: DatumValue) => dayjs(values).format(isDailyPeriod ? 'HH:mm' : 'DD.MM'),
        }),
        [dayjs, isDailyPeriod],
    );

    return (
        <Section className="graph-section">
            <PeriodSectionHeader
                title={title}
                containerStyle={{ maxWidth: 880 }}
                period={period}
                setPeriod={setPeriod}
                timezone={timezone}
            />
            <Section>
                <SectionHeader.Sub title={graphTitle} />
                <Graph className="line-chart-body chart">
                    <GraphLoader spinning={waterVolumeLoading} />
                    {chartDataLength > 0 ? (
                        <BaseLineGraph
                            data={chartData.series}
                            axisLeftProps={axisLeftProps}
                            axisBottomProps={axisBottomProps}
                            tooltip={WaterVolumeTooltip}
                            areaBaselineValue={0}
                            {...minMaxValues}
                        />
                    ) : (
                        <EmptyPanel />
                    )}
                </Graph>
            </Section>
        </Section>
    );
};

export default VolumeGraph;
