import { AuthMethod } from "../../routes/login/types";

export default {
    title: 'Authorization',
    form: {
        fields: {
            username: {
                placeholder: "example@mail.com",
                label: "E-mail",
                validations: {
                    require: "this field is required",
                    email: "You specified an invalid email address"
                }
            },
            password: {
                label: "Password",
                validations: {
                    require: "this field is required",
                }
            },
            rememberMe: {
                label: "Remember me",
            },
            phoneNumber: {
                label: "Phone number",
                validations: {
                    phone: "You specified an invalid phone number",
                    require: "This field is required",
                }
            }
        },
        submitButton: "log In",
        authMethods: {
            [AuthMethod.Phone]: "By phone number",
            [AuthMethod.Email]: "By E-mail"
        },
    },
    links: {
        registration: 'Registration',
        recoveryPassword: 'I forgot my password :('
    }
}