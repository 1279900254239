export default {
    title: 'SMS report',
    table: {
        phone: 'Phone number',
        message: 'Message',
        cost: 'Cost',
        createdAt: 'Ceated at',
        deliveryDate: 'Delivered in',
        type: 'Type',
        totalCost: 'Total cost',
        reportDownload: 'Download report for the selected period',
    },
    type: {
        authorization: 'Authorization',
        marketing: 'Marketing',
    },
    selector: {
        prefix: 'Message type:',
        notSelected: 'not selected',
    },
};
