import React, { forwardRef } from 'react';

import { default as AntdIcon, IconComponentProps } from '@ant-design/icons/es/components/AntdIcon';

export type AntSvgIconProps = Omit<IconComponentProps, 'ref'>;

const AntSvgIcon = forwardRef<HTMLSpanElement, AntSvgIconProps>(
    (props: AntSvgIconProps, ref) => {
        return <AntdIcon ref={ref} {...props} />;
    },
);

export default AntSvgIcon;
