import I18n from './aqua-delivery-web-client-ui/i18n';
import {
    Source,
    setConfig as setCustomFetchConfig,
} from './aqua-delivery-web-client-ui/customFetch';

export type ServiceName = keyof ConfigApp['api'];

type Api<T> = T & {
    domain: string;
    base: string;
    enrichToken?: string;
};

type ConfigApp = {
    source: Source;
    version: string;
    tokenStoreKey: string;
    refreshTokenStoreKey: string;
    cloudPaymentsPublicId: string;
    apiUrl: string;
    policylUrl: string;
    contractUrl: string;
    partnerLoginUrl: string;
    payPalClientId: string;
    knowledgeUrl: string;
    addressAutocomplete: string;
    paymentMethodsAvailability: {
        isCloudPaymentEnabled: boolean;
        isPayPalEnabled: boolean;
    };
    integrations: {
        amo: {
            redirectUri: string;
            secretsUri: string;
            logo: string;
        };
    };
    metrics: {
        ga: string;
        ym: number[];
        gtm: string;
    };
    dashboard: {
        phone: string;
        documents: { title: string; link: string }[];
    };
    contactEmail: string;
    isAppUpdating: boolean;
    invoiceClientUrl: string;
    api: {
        auth: Api<{
            getToken: string;
            refreshTokenUrl: string;
            resetPasswordRequest: string;
            confirmSms: string;
        }>;
        partner: Api<{}>;
        courier: Api<{}>;
        temporal: Api<{}>;
        wiki: Api<{}>;
        marketing: Api<{}>;
        sms: Api<{}>;
        billing: Api<{}>;
        pricingPlans: Api<{}>;
        payment: Api<{}>;
        deliveryPeriod: Api<{}>;
        invoice: Api<{}>;
        iot: Api<{}>;
        amo: Api<{}>;
    };
};

const devAuthBaseUrlStoreKey = 'auth_service_url';
const devPartnerBaseUrlStoreKey = 'partner_service_url';
const devCourierBaseUrlStoreKey = 'courier_service_url';
const devTemporalBaseUrlStoreKey = 'temporal_service_url';
const devWikiBaseUrlStoreKey = 'wiki_service_url';
const devMarketingBaseUrlStoreKey = 'marketing_service_url';
const devSMSBaseUrlStoreKey = 'sms_service_url';
const devBillingBaseUrlStoreKey = 'billing_service_url';
const devPaymentBaseUrlStoreKey = 'payment_service_url';
const devDeliveryPeriodBaseUrlStoreKey = 'delivery_period_service_url';
const devInvoiceBaseUrlStoreKey = 'invoice_service_url';
const devIotBaseUrlStoreKey = 'iot_service_url';
const devAmoBaseUrlStoreKey = 'amo_service_url';

export const getEnvVariable = (key: string) => {
    // @ts-ignore
    const env = window.env;
    let values;
    if (env[I18n.locale]) {
        values = env[I18n.locale][key];
    } else if (env['ru'][key] !== undefined) {
        values = env['ru'][key];
    }

    if (values !== undefined) return values;
    throw Error(`Need add key ${key} into .env.local`);
};

const getBaseUrl = (localStorageKey: string, envKey: string) => {
    const url = localStorage.getItem(localStorageKey) || getEnvVariable(envKey);
    return url;
};

let conf: ConfigApp | undefined = undefined;

export const initConfig = () => {
    const baseAuthApi = getBaseUrl(devAuthBaseUrlStoreKey, 'authServiceUrl');
    const basePartnerApi = getBaseUrl(devPartnerBaseUrlStoreKey, 'partnerServiceUrl');
    const baseCourierApi = getBaseUrl(devCourierBaseUrlStoreKey, 'courierServiceUrl');
    const baseTemporalApi = getBaseUrl(devTemporalBaseUrlStoreKey, 'temporalServiceUrl');
    const baseWikiApi = getBaseUrl(devWikiBaseUrlStoreKey, 'wikiServiceUrl');
    const baseMarketingApi = getBaseUrl(devMarketingBaseUrlStoreKey, 'marketingServiceUrl');
    const baseSMSApi = getBaseUrl(devSMSBaseUrlStoreKey, 'smsServiceUrl');
    const baseBillingApi = getBaseUrl(devBillingBaseUrlStoreKey, 'billingServiceUrl');
    const basePaymentApi = getBaseUrl(devPaymentBaseUrlStoreKey, 'paymentServiceUrl');
    const baseDeliveryPeriodApi = getBaseUrl(
        devDeliveryPeriodBaseUrlStoreKey,
        'deliveryPeriodServiceUrl',
    );
    const basePricingPlansApi = getBaseUrl(devPartnerBaseUrlStoreKey, 'pricingPlanServiceUrl');
    const baseInvoiceApi = getBaseUrl(devInvoiceBaseUrlStoreKey, 'invoiceServiceUrl');
    const baseIotApi = getBaseUrl(devIotBaseUrlStoreKey, 'iotServiceUrl');
    const baseAmoApi = getBaseUrl(devAmoBaseUrlStoreKey, 'amoServiceUrl');

    const invoiceClientUrl = getEnvVariable('invoiceWebclientUrl');

    conf = {
        source: 'partner_cabinet',
        version: '0.0.2',
        tokenStoreKey: 'token',
        refreshTokenStoreKey: 'refresh_token',
        cloudPaymentsPublicId: getEnvVariable('cloudPaymentToken'),
        apiUrl: getEnvVariable('apiUrl'),
        policylUrl: getEnvVariable('policyUrl'),
        contractUrl: getEnvVariable('contractUrl'),
        partnerLoginUrl: getEnvVariable('partnerLoginUrl'),
        payPalClientId: getEnvVariable('payPalClientId'),
        integrations: getEnvVariable('integrations'),
        knowledgeUrl: getEnvVariable('knowledgeUrl'),
        addressAutocomplete: getEnvVariable('addressAutocomplete'),
        dashboard: getEnvVariable('dashboard'),
        contactEmail: getEnvVariable('contactEmail'),
        isAppUpdating: getEnvVariable('isAppUpdating'),
        paymentMethodsAvailability: getEnvVariable('paymentMethodsAvailability'),
        invoiceClientUrl,
        metrics: {
            ga: getEnvVariable('googleAnalytics'),
            ym: getEnvVariable('yandexMetrika'),
            gtm: getEnvVariable('googleTagManager'),
        },
        api: {
            auth: {
                domain: baseAuthApi,
                base: `${baseAuthApi}/auth/graphql`,
                getToken: `${baseAuthApi}/auth/login/token/get`,
                refreshTokenUrl: `${baseAuthApi}/auth/login/token/refresh`,
                resetPasswordRequest: `${baseAuthApi}/auth/reset_password_requests`,
                confirmSms: `${baseAuthApi}/auth/reset_password_token_requests`,
            },
            partner: {
                domain: basePartnerApi,
                base: `${basePartnerApi}/partner/graphql`,
                enrichToken: `${basePartnerApi}/partner/enrich_token_requests`,
            },
            courier: {
                domain: baseCourierApi,
                base: `${baseCourierApi}/courier/graphql`,
                enrichToken: `${basePartnerApi}/partner/enrich_token_requests`,
            },
            temporal: {
                domain: baseTemporalApi,
                base: `${baseTemporalApi}/graphql`,
                enrichToken: `${basePartnerApi}/partner/enrich_token_requests`,
            },
            wiki: {
                domain: baseWikiApi,
                base: `${baseWikiApi}/wiki/graphql`,
            },
            marketing: {
                domain: baseMarketingApi,
                base: `${baseMarketingApi}/marketing/graphql`,
                enrichToken: `${basePartnerApi}/partner/enrich_token_requests`,
            },
            sms: {
                domain: baseSMSApi,
                base: baseSMSApi,
            },
            billing: {
                domain: baseBillingApi,
                base: `${baseBillingApi}/billing/graphql`,
                enrichToken: `${basePartnerApi}/partner/enrich_token_requests`,
            },
            pricingPlans: {
                domain: basePricingPlansApi,
                base: `${basePricingPlansApi}/pricing-plan/graphql`,
                enrichToken: `${basePartnerApi}/partner/enrich_token_requests`,
            },
            payment: {
                domain: basePaymentApi,
                base: `${basePaymentApi}/payment/graphql`,
                enrichToken: `${basePartnerApi}/partner/enrich_token_requests`,
            },
            deliveryPeriod: {
                domain: baseDeliveryPeriodApi,
                base: `${baseDeliveryPeriodApi}/delivery-period/graphql`,
                enrichToken: `${basePartnerApi}/partner/enrich_token_requests`,
            },
            invoice: {
                domain: baseInvoiceApi,
                base: `${baseInvoiceApi}/invoice/graphql`,
                enrichToken: `${basePartnerApi}/partner/enrich_token_requests`,
            },
            iot: {
                domain: baseIotApi,
                base: `${baseIotApi}/iot/graphql`,
                enrichToken: `${basePartnerApi}/partner/enrich_token_requests`,
            },
            amo: {
                domain: baseAmoApi,
                base: `${baseAmoApi}/amo/graphql`,
                enrichToken: `${basePartnerApi}/partner/enrich_token_requests`,
            },
        },
    };

    setCustomFetchConfig(config.source, config.version);
};

export const config = {
    get source() {
        return conf!.source;
    },
    get version() {
        return conf!.version;
    },
    get tokenStoreKey() {
        return conf!.tokenStoreKey;
    },
    get refreshTokenStoreKey() {
        return conf!.refreshTokenStoreKey;
    },
    get api() {
        return conf!.api;
    },
    get cloudPaymentsPublicId() {
        return conf!.cloudPaymentsPublicId;
    },
    get apiUrl() {
        return conf!.apiUrl;
    },
    get policylUrl() {
        return conf!.policylUrl;
    },
    get contractUrl() {
        return conf!.contractUrl;
    },
    get partnerLoginUrl() {
        return conf!.partnerLoginUrl;
    },
    get metrics() {
        return conf!.metrics;
    },
    get dashboard() {
        return conf!.dashboard;
    },
    get contactEmail() {
        return conf!.contactEmail;
    },
    get payPalClientId() {
        return conf!.payPalClientId;
    },
    get paymentMethodsAvailability() {
        return conf!.paymentMethodsAvailability;
    },
    get integrations() {
        return conf!.integrations;
    },
    get isAppUpdating() {
        return conf!.isAppUpdating;
    },
    get knowledgeUrl() {
        return conf!.knowledgeUrl;
    },
    get invoiceClientUrl() {
        return conf!.invoiceClientUrl;
    },
    get addressAutocomplete() {
        return conf!.addressAutocomplete;
    },
};
